import React, { Component } from 'react'

import styles from './OrderPlaced.module.css'

import back from '../../assets/images/back.svg'
import orderplaced from '../../assets/images/orderplaced.svg'

import { getOrder } from '../../apis';


import { Link } from 'react-router-dom'

export class OrderPlaced extends Component {
    state = {
        orderDetails: ''
    }
    async componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const orderId = urlParams.get('orderId')
        
        var data =  await getOrder(orderId);
        this.setState({orderDetails: data})
    }
    render() {
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                    <Link to="/" className={styles.back}>
                        <img src={back} fluid className={styles.backicon} alt="back"/>
                    </Link>

                    <div className={styles.innerBody}>
                        <div className={styles.left}>
                            <div className={styles.lTitle}>Order Placed<br/>Successfully</div>
                            <div className={styles.lSubtitle}>Delivery timings: 9.00am to 9.00pm</div>

                            <div className={styles.whiteCard}> 
                                <div className={styles.topSection}>
                                    <div className={styles.row}>
                                        <div className={styles.lText}>Order Id</div>
                                        <div className={styles.rText}>{this.state.orderDetails.id}</div>
                                    </div>
                                    <div className={styles.row}>
                                        <div className={styles.lText}>Estimate Duration</div>
                                        <div className={styles.rText}>{this.state.orderDetails.delivery_time}</div>
                                    </div>
                                </div>
                                <div className={styles.bottom}>
                                    <div className={styles.row}>
                                        <div className={styles.lTextG}>Total</div>
                                        <div className={styles.rTextG}>{this.state.orderDetails.final_amount} ₹</div>
                                    </div>
                                    <div className={styles.inclusive}>Inclusive of all taxes</div>
                                </div>
                                <div className={styles.bottomText}>Your invoice will be sent to you on your email</div>
                            </div>

                            <div className={styles.brownBox}>
                                <div className={styles.lBottomTitle}>Having trouble placing your order?</div>
                                <div className={styles.lBottomSubtitle}>Call us right away!</div>
                                <div className={styles.phoneDiv}>
                                    <a className={styles.lBottomPhone} href="tel:+911204030499">+911204030499</a>
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.rightImg}>
                                <img src={orderplaced} fluid className={styles.orderplaced} alt="orderplaced"/>
                            </div>
                            <a href="/" className={styles.backButton}>Back to Homepage</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default OrderPlaced
