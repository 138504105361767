import React, { Component } from 'react'
import { addProductToCart, requestProduct } from '../../apis';
import styles from './ProductCard.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cx from "classnames";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
export class ProductCard extends Component {

    addToCart = async (event, item) => {
        event.preventDefault();
        if (localStorage.getItem('user')) {
            event.preventDefault()
            toast("Product added to cart!")
            await addProductToCart(item.id)
            this.props.changeCartCount();
        } else {
            alert("Please sign in first!");
        }
    }

    requestProduct = async (event, item) => {
        event.preventDefault();
        if (localStorage.getItem('user')) {
            event.preventDefault()
            toast("Product requested!")
            console.log("PRODUCT REQUESTED")
            console.log(item)
            await requestProduct(item.id)
        } else {
            alert("Please sign in first!");
        }
    }

    render() {
        const LightTooltip = withStyles((theme) => ({
            tooltip: {
              backgroundColor: "#E0F3F1",
              color: "#242847",
      
              fontSize: 14,
              fontFamily: "Poppins",
            },
            arrow: {
              color: "#E0F3F1",
            },
          }))(Tooltip);
        var item = this.props.product;
       
        return (
            <div>
                <div className={styles.cardContainer}>
                {item.medicine && item.medicine.is_scheduled &&
                    <LightTooltip
                        title={
                            "This is a Schedule H drug and requires a valid prescription from a qualified doctor"
                        }
                        placement="top"
                        arrow
                    > 
                        <div className={cx(styles.hMarker)}>H</div>
                    </LightTooltip>}
                    {   item.img_url !== "" ?
                        <img src={item.img_url} className={styles.cardImg} alt="item"/> :
                        <img src="https://api.drome.app/media/productimages/default_tablet.png" className={styles.cardImg} alt="item"/>
                    }
                    <div className={styles.productDescription}>
                        <b>{item.name}</b> {item.short_description ? ' - ' + item.short_description : <div/>}
                    </div>
                    {
                        item.inventory_qty > 0 ?
                        <div className={styles.priceDiv}>
                       <div>
                            <div className={styles.price}>
                                ₹{item.price}
                            </div>
                            <div className={styles.discount}>
                                {item.discount ? "₹ " + item.discount + " Off" : ""}
                            </div>
                       </div> 
                        <div onClick={(event) => this.addToCart(event, item)} className={styles.priceButton}>
                            Add to Cart
                        </div>
                    </div> :
                    <div className={styles.priceDiv}>
                       <div>
                            <div style={{color:"#black"}} className={styles.discount}>
                                Out of stock
                            </div>
                       </div> 
                        <div style={{backgroundColor:"#FFF2F1"}} onClick={(event) => this.requestProduct(event, item)} className={styles.requestButton}>
                            Request
                        </div>
                    </div>
                    }
                </div>
                <ToastContainer 
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    onClick={() => window.location.href = "/cart"}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
            </div>
        )
    }
}

export default ProductCard
