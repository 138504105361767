import React, { Component } from 'react'

import styles from './ScheduleCall.module.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import cross from '../../../assets/images/cross.svg'
import videocall from '../../../assets/images/callWithDoc.svg'
import { Link } from 'react-router-dom';

export class ScheduleCall extends Component {

    state = {
        name: '',
        mobile: '',
        email: '',
        bookingFor: 'self',
        timeSlot: '8Ato9P',
        date: '',
    }

    bookVideoCall = () => {
        alert("Videocall Scheduled!");
        window.location.href = "/";
        // setTimeout(function(){ window.location.href = "/"; }, 3000);
    }

    getPrice = () => {
        if (this.state.timeSlot === '8Ato9P') {
            return 800;
        } else {
            return 1500;
        }
    }

    render() {
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                    <div className={styles.bluebox}>
                        <Link to="/" className={styles.crossDiv}>
                            <img src={cross} fluid className={styles.cross} alt="cross"/>
                        </Link>
                        <div className={styles.outerDiv}>
                            <div className={styles.leftDiv}>
                                <div className={styles.leftText}>
                                    <div className={styles.leftDivTitle}>Schedule a call<br/>with your doctor</div>
                                    <div className={styles.leftDivSub}>Our Team of Doctors at your service,<br/>At your Home</div>
                                    {/* <div>
                                        <div className={styles.leftDivFreeConsultation}>Our first consultation is free</div>
                                    </div> */}
                                </div>
                                <div className={styles.imgDiv}>
                                    <img src={videocall} fluid className={styles.videocall} alt="videocall"/>
                                </div>
                            </div>
                            <div className={styles.rightDiv}>
                                <div className={styles.row}>
                                    <div className={styles.tag}>01 Select a Date*</div>
                                    <div className={styles.value}>
                                        <input required className={styles.inputText} type="date" placeholder="e.g. 09/02/2021" value={this.state.date} onChange={(event) => this.setState({date: event.target.value})} />
                                    </div>
                                </div>
                                {/* <div className={styles.row}>
                                    <div className={styles.tag}>02 Select a Slot*</div>
                                    <div className={styles.value}>
                                        <input className={styles.inputText} type="text" placeholder="Mobile No." value={this.state.mobile} onChange={(event) => this.setState({mobile: event.target.value})} />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.tag}></div>
                                    <div className={styles.value}>
                                        <input className={styles.inputText} type="text" placeholder="Email" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.tag}>Call Purpose</div>
                                    <div className={styles.value}>
                                        <select className={styles.inputSelect} value={this.state.callPurpose} onChange={(event) => this.setState({callPurpose: event.target.value})}>
                                            <option className={styles.option} value="Trouble Ordering">Trouble Ordering</option>
                                            <option className={styles.option} value="General Enquiry">General Enquiry</option>
                                        </select>
                                    </div>
                                </div> */}
                                <div className={styles.row}>
                                    <div style={{lineHeight: 1.2}} className={styles.tag}>02 Select Slot*</div>
                                    <div className={styles.timeslotsDiv}>
                                        <div onClick={() => this.setState({timeSlot: '8Ato9P'})} className={this.state.timeSlot === '8Ato9P' ? styles.timeslotActive : styles.timeslot}>Daytime Consultation<br/><br/><b>08:00 am to 09:00 pm</b></div>
                                        <div onClick={() => this.setState({timeSlot: '9Pto8A'})} className={this.state.timeSlot === '9Pto8A' ? styles.timeslotActive : styles.timeslot}>Extended Consultation<br/><br/><b>09:00 pm to 08:00 am</b></div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.tag}>03 Patient's Details*</div>
                                    <div className={styles.value}>
                                        <input style={{marginRight: 5}} className={styles.inputTextDetails} type="text" placeholder="Name" value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} />
                                        <input style={{marginLeft: 5}} className={styles.inputTextDetails} type="phone" placeholder="Mobile No." value={this.state.mobile} onChange={(event) => this.setState({mobile: event.target.value})} />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.tag}></div>
                                    <div className={styles.value}>
                                        <input className={styles.inputTextEmail} type="email" placeholder="Email Id" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div style={{lineHeight: 1.2}} className={styles.tag}>04 Booking For*</div>
                                    <div className={styles.bookingForDiv}>
                                        <div onClick={() => this.setState({bookingFor: 'self'})} className={this.state.bookingFor === 'self' ? styles.bookingForActive : styles.bookingFor}>Self</div>
                                        <div onClick={() => this.setState({bookingFor: 'other'})} className={this.state.bookingFor === 'other' ? styles.bookingForActive : styles.bookingFor}>Other</div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div style={{lineHeight: 1.2}} className={styles.tag}></div>
                                    <div className={styles.value}></div>
                                </div>
                                <div className={styles.rowBook}>
                                    <div className={styles.bookText}>Book it for a price of <br/><span className={styles.price}>₹{this.getPrice()}</span></div>
                                    <div onClick={() => this.bookVideoCall()} className={styles.book}>Book</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

export default ScheduleCall
