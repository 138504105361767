import fetchIntercept from 'fetch-intercept';
import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.module.css';

import Home from './pages/PharmacyHome/Home';
import PrescriptionUpload from './pages/PrescriptionUpload/PrescriptionUpload';
import Status from './pages/Status/Status';
import Cart from './pages/Cart/Cart';
import ViewAll from './pages/ViewAll/ViewAll';
import Description from './pages/Description/Description';
import OrderHistory from './pages/OrderHistory/OrderHistory';
import OrderPlaced from './pages/OrderPlaced/OrderPlaced';
import { Profile } from './pages/Profile/Profile';
import { DeliveryDetails } from './pages/DeliveryDetails/DeliveryDetails';
import PaymentDetails from './pages/PaymentDetails/PaymentDetails';

import firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from './services/firebaseConfig';
import VideoCallAppointment from './pages/VideoCallAppointment/VideoCallAppointment';
import ScheduleCall from './components/misc/ScheduleCall/ScheduleCall';
import SubscribePlan from './components/misc/SubscribePlan/SubscribePlan';
import { getCart } from './apis';
import Header from './components/Header/Header';
import { ToastProvider, useToasts } from "react-toast-notifications";
import 'react-toastify/dist/ReactToastify.css';
import cx from "classnames";
import styles from "./App.module.css";
import errorImg from "./assets/images/error.svg";


const MyCustomToast = ({ appearance, children }) => (
  <div
    className={cx(
      appearance === "success"
        ? styles.successToaster
        : appearance === "error"
        ? styles.errorToaster
        : styles.infoToaster,
      "hcFlex"
    )}
  >
    {appearance === "error" ? (
      <img src={errorImg} className={cx(styles.errorImg)} loading="eager" alt={"errorImg"}></img>
    ) : (
      ""
    )}
    <div className={styles.toasterText}>{children}</div>
  </div>
);
export const Wrapper = (wrapperProps) => {
  
  const { addToast } = useToasts();
  const showSuccess = (message) => {
    addToast(message, {
      appearance: "success",
      autoDismiss: true,
    });
  };
  const showError = (error) => {
    const errorMessage =
      error &&
      error.response &&
      error.response &&
      error.response.data &&
      error.response.data.error
        ? error.response.data.error
        : error.message;
    addToast(errorMessage, {
      appearance: "error",
      autoDismiss: true,
    });
  };
  fetchIntercept.register({
    request: function (url, config) {
        // Modify the url or config here
        return [url, config];
    },
 
    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        // console.log("error occurred");
        return Promise.reject(error);
    },
 
    response: async function (response) {
        // Modify the reponse objects
        if(!response.ok)
        {
          let responseBody = await response.json();
          console.log(responseBody);
          showError(new Error(responseBody.error));
          // toast("owihcwb");
        }
        return response;
    },
 
    responseError: function (error) {
        // Handle an fetch error
      
        return Promise.reject(error);
    }
});
  const additionalProps = {
    showSuccess: showSuccess,
    showError: showError,
    ...wrapperProps,
  };
  return (
    <Router>
        
    <Switch>

      <Route exact path="/" render={(props) => (
        <Home {...props} {...additionalProps}></Home>
      )}/>
      <Route exact path="/prescription-upload" component={PrescriptionUpload}/>
      <Route exact path="/status" component={Status} />
      <Route exact path="/cart" component={Cart} /> 
      <Route exact path="/description" component={Description} />
      <Route exact path="/order-history" component={OrderHistory} />
      <Route exact path="/order-placed" component={OrderPlaced} />
      <Route exact path="/view-all" component={ViewAll} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/delivery-details" component={DeliveryDetails} />
      <Route exact path="/payment-details" component={PaymentDetails} />
      <Route exact path="/videocall-appointment" component={VideoCallAppointment} />
      <Route exact path="/schedule-call" component={ScheduleCall} />
      <Route exact path="/subscribe-plan" component={SubscribePlan} />
    </Switch>
  </Router>
  );
};
class App extends Component {
  constructor(){
    super();
    this.state = {
      cartCount : 0
    }
    this.changeCartCount = this.changeCartCount.bind(this);
    this.interceptRequests = this.interceptRequests.bind(this);
  }
  interceptRequests(){
    
  }
  componentDidMount() {
    this.interceptRequests();
    firebase.initializeApp(firebaseConfig);
    this.changeCartCount();
  }

  changeCartCount = async() => {
    var data = await getCart();
    if (data && data.total_items) {
        this.setState({cartCount: data.total_items});
    }
}
  render() {
    return (
      <div className="App">
        <Header cartCount={this.state.cartCount} />
       
        <ToastProvider
          components={{ Toast: MyCustomToast }}
          placement="top-center"
        >
          {/* this wrapper created so that it becomes easy for showing toaster just from single place*/}
          <Wrapper
          changeCartCount={this.changeCartCount}
            
          ></Wrapper>
          
        </ToastProvider>
      </div>
    );
  }
}

export default App;
