


export const login = async () => {
    try {
      var data = {};
      // await fetch(process.env.REACT_APP_API_BASE_URL+'users/login/', {
      //       method:'get',
      //       credentials: 'include'
      //   })
      //       .then(response => response.json())
      //       .then(res => {
      //           data = res;
      //           localStorage.setItem('userToken', res.token);
      //       });
      return data;
    } catch (error) {
      throw error;
    }
};

export const getProductList = async (category, page) => {
    try {
      await login();
      var data = {};
      await fetch(process.env.REACT_APP_API_BASE_URL+'products/category/?category='+category+`&page=`+page, {
        method:'get',
        credentials: 'include'
    })
        .then(response => response.json())
        .then(res => {
            data = res;
        });
      return data;
    } catch (error) {
      throw error;
    }
};

export const addProductToCart = async (productid) => {
  await login();
    var formdata = new FormData();
    formdata.append("product_id", productid);

    var requestOptions = {
    method: 'POST',
    credentials: 'include',
    body: formdata,
    redirect: 'follow'
    };

    await fetch(process.env.REACT_APP_API_BASE_URL+'orders/cart/add-item/', requestOptions)
    .then(response => response.json())
    .then(result => {
        return result;
    })
    .catch(error => console.log('error', error));
};

export const requestProduct = async (productid) => {
  await login();

    var requestOptions = {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow'
    };

    await fetch(process.env.REACT_APP_API_BASE_URL+'orders/customer-request/?product_id='+productid, requestOptions)
    .then(response => response.json())
    .then(result => {
        return result;
    })
    .catch(error => console.log('error', error));
};

export const getCart = async () => {
  await login();
  var requestOptions = {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow'
  };
  var data;
  await fetch(process.env.REACT_APP_API_BASE_URL+'orders/cart/', requestOptions)
    .then(response => response.json())
    .then(result => {
      data = result;
    })
    .catch(error => console.log('error', error));
    return data;
};

export const getOrderById = async (id) => {
  await login();
  var requestOptions = {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow'
  };
  var data;
  await fetch(process.env.REACT_APP_API_BASE_URL+`orders/${id}/`, requestOptions)
    .then(response => response.json())
    .then(result => {
      data = result;
    })
    .catch(error => console.log('error', error));
    return data;
};

export const editCartQuantity = async (product_id, quantity) => {
  await login();
  var formdata = new FormData();
  formdata.append("cart_item_id", product_id);
  formdata.append("quantity", quantity);

  var requestOptions = {
    method: 'POST',
    credentials: 'include',
    body: formdata,
    redirect: 'follow'
  };
  var data;
  await fetch(process.env.REACT_APP_API_BASE_URL+'orders/cart/edit-quantity/', requestOptions)
    .then(response => response.json())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const deleteProductCart = async (product_id) => {
  await login();
  var requestOptions = {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow'
  };
  
  fetch(process.env.REACT_APP_API_BASE_URL+'orders/cart/'+product_id+'/delete-item/', requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
};

export const getOrderHistory = async () => {
  await login();
  var requestOptions = {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow'
  };
  var data;
  
  await fetch(process.env.REACT_APP_API_BASE_URL+'orders/', requestOptions)
    .then(response => response.json())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const repeatOrder = async (order_id) => {
  await login();
  var data;

  var requestOptions = {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow'
  };

  await fetch(process.env.REACT_APP_API_BASE_URL+'orders/repeat-order/'+order_id+'/', requestOptions)
    .then(response => response.json())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const getUser = async () => {
  await login();
  var data;

  var requestOptions = {
    method: 'GET',
    credentials: 'include',
    redirect: 'follow'
  };
  
  await fetch(process.env.REACT_APP_API_BASE_URL + 'users/', requestOptions)
    .then(response => response.json())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const postUser = async (user) => {
  await login();
  var data;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(user);

  var requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + 'users/', requestOptions)
    .then(response => response.text())
    .then(result => {
      data = result
      localStorage.setItem('user', data);
    })
    .catch(error => console.log('error', error));

  return data;
};

export const getOrder = async (orderId) => {
  var data;
  var requestOptions = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
  };

  await fetch(process.env.REACT_APP_API_BASE_URL+'orders/'+orderId+'/', requestOptions)
    .then(response => response.json())
    .then(result => data = result)
    .catch(error => console.log('error', error));

    return data

}

export const createOrder = async () => {
  await login();
  var data;

  var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    credentials: 'include',
  };

  await fetch(process.env.REACT_APP_API_BASE_URL+'orders/', requestOptions)
    .then(response => response.json())
    .then(result => {
      data = result
      console.log("HHH")
      console.log(data)
    })
    .catch(error => console.log('error', error));

  var PaymentDetails;

  await fetch(process.env.REACT_APP_API_BASE_URL+'payments/order/'+data.id+'/', requestOptions)
  .then(response => response.json())
  .then(result => {
    PaymentDetails = result
    console.log("OPP")
    console.log(PaymentDetails)
  })
  .catch(error => console.log('error', error));

  var response = {
    'data': data,
    'orderId': PaymentDetails.order_id,
    'paymentId': PaymentDetails.payment_id
  }
  
  return response;
};

export const applyPromoCode = async (promo_code) => {
  // let body = {
  //   promo_code
  // };
  // try {
  //   const { data } = await axios.post(
  //     `${process.env.REACT_APP_API_BASE_URL}orders/cart/apply-promotion/`,
  //     body
  //   );
  //   return data;
  // } catch (error) {
  //   throw error;
  // }
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  

  var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    credentials: 'include',
    headers: myHeaders,
    body:JSON.stringify({
      promo_code
    })
  };

  return await fetch(process.env.REACT_APP_API_BASE_URL + 'orders/cart/apply-promotion/', requestOptions)
  .then(response => response.json())
  .catch(err => err);
 
};
export const applyPromoCodeForOrder = async (promo_code , order_id) => {
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    credentials: 'include',
    headers: myHeaders,
    body:JSON.stringify({
      promo_code
    })
  };

  return await fetch(process.env.REACT_APP_API_BASE_URL + `orders/${order_id}/apply-promotion/`, requestOptions)
  .then(response => response.json())
  .catch(err => err);
 
};

export const removePromoCode = async (promo_code) => {
 
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  

  var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    credentials: 'include',
    headers: myHeaders,
   
  };

  return await fetch(process.env.REACT_APP_API_BASE_URL + 'orders/cart/remove-promotion/', requestOptions)
  .then(response => response.json())
  .catch(err => err);
 
};
export const removePromoCodeForOrder = async (order_id) => {
 
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  

  var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    credentials: 'include',
    headers: myHeaders,
   
  };

  return await fetch(process.env.REACT_APP_API_BASE_URL + `orders/${order_id}/remove-promotion/`, requestOptions)
  .then(response => response.json())
  .catch(err => err);
 
};


export const payForOrder = async (id) => {
  await login();
  var data;

  var requestOptionsGetOrder = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
  };

  var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    credentials: 'include',
  };

  await fetch(process.env.REACT_APP_API_BASE_URL+`orders/${id}/`, requestOptionsGetOrder)
    .then(response => response.json())
    .then(result => {
      data = result
      console.log("HHH")
      console.log(data)
    })
    .catch(error => console.log('error', error));

  var PaymentDetails;

  await fetch(process.env.REACT_APP_API_BASE_URL+'payments/order/'+id+'/', requestOptions)
  .then(response => response.json())
  .then(result => {
    PaymentDetails = result
    console.log("OPP")
    console.log(PaymentDetails)
  })
  .catch(error => console.log('error', error));

  var response = {
    'data': data,
    'orderId': PaymentDetails.order_id,
    'paymentId': PaymentDetails.payment_id
  }
  
  return response;
};

export const paymentInfo = async (data) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var Paymentdata;

  var requestOptions = {
    method: 'POST',
    redirect: 'follow',
    credentials: 'include',
    headers: myHeaders,
    body: data.response
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + 'payments/' + data.paymentId + '/', requestOptions)
  .then(response => response.json())
  .then(result => Paymentdata = result)
  .catch(error => console.log('error', error));

  return Paymentdata;
}

export const socialLogin = async (user) => {
  await login();
  var data;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(user);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    //credentials: 'same-origin'
    credentials: 'include'
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + "users/login-social/", requestOptions)
    .then(response => response.text())
    .then(result => {
      data = result
      localStorage.setItem('user', data);
    })
    .catch(error => console.log('error', error));

  return data;
};

export const mobileOTP = async (mobile) => {
  await login();
  var data;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(mobile);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include',
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + "users/login-otp/", requestOptions)
    .then(response => response.text())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const verifyMobileOTP = async (otp, verify_id) => {
  await login();
  var data;

  console.log(verify_id)
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({otp: otp});

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include',
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + `users/verify-otp/${verify_id}/`, requestOptions)
    .then(response => response.text())
    .then(result => {
      data = result
      localStorage.setItem('user', data);
    })
    .catch(error => console.log('error', error));

  return data;
};

export const userLogout = async () => {
  await login();
  var data;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include',
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + `users/logout/`, requestOptions)
    .then(response => response.text())
    .then(result => {
      data = result
      document.cookie = "cookiename=token; expires = Thu, 01 Jan 1970 00:00:00 GMT"
      console.log("LOGOUT")
      console.log(data)
      console.log(document.cookie)
    })
    .catch(error => console.log('error', error));

  return data;
};

export const callPharmacist = async (bodyData) => {
  await login();
  var data;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(bodyData);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include'
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + `orders/pharmacist-call/`, requestOptions)
    .then(response => response.text())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const searchList = async (name) => {
  await login();
  var data;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include'
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + `products/search-list/?name=${name}`, requestOptions)
    .then(response => response.text())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const searchSuggestions = async (name) => {
  await login();
  var data;
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include'
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + `products/search/?input=${name}`, requestOptions)
    .then(response => response.text())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const liveOrders = async () => {
  await login();
  var data;
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include'
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + `orders/live-order/`, requestOptions)
    .then(response => response.text())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const getLatestDocAppointment = async () => {
  await login();
  var data;
  
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include'
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + `users/appointment/`, requestOptions)
    .then(response => response.text())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const createPlanSubscription = async (bodyData) => {
  await login();
  var data;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(bodyData);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include'
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + `orders/subscription/`, requestOptions)
    .then(response => response.text())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const payForSubscription = async (id) => {
  await login();
  var data;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({});

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include'
  };

  var url = process.env.REACT_APP_API_BASE_URL + `payments/subscription/${id}/`;
  console.log(url)

  await fetch(process.env.REACT_APP_API_BASE_URL + `payments/subscription/${id}/`, requestOptions)
    .then(response => response.text())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};

export const paymentSuccess = async (id, bodyData) => {
  await login();
  var data;

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(bodyData);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include'
  };

  await fetch(process.env.REACT_APP_API_BASE_URL + `payments/${id}/`, requestOptions)
    .then(response => response.text())
    .then(result => data = result)
    .catch(error => console.log('error', error));

  return data;
};
// unregister();
