import React, { Component } from 'react'
import styles from './Footer.module.css'

export class Footer extends Component {
    render() {
        return (
            <div className={styles.footer}>
                <div className={styles.divblock24}>
                    <div className={styles.textblock24}>Consultation Charges</div>
                    <div className={styles.textblock25}>1500/- per patient (8.00 am to 9.00 pm)<br/>2000/- per patient (6.00 am - 8.00 am or 9.00 pm-11.00 pm)</div>
                </div>
                <div className={styles.divblock23}>
                    <div className={styles.divblock25}>
                        <div className={styles.textblock39}>Copyright @2020.Drome Services. All rights reserved.</div>
                    </div>
                    <div className={styles.divblock26}>
                        <a className={styles.link} target="blank" href="https://drome.webflow.io/privacy-policy">
                            <div className={styles.textblock26}>Privacy Policy</div>
                        </a>
                        <a className={styles.link} target="blank" href="https://drome.webflow.io/terms-conditions">
                            <div className={styles.textblock27}>Terms and Conditions</div>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
