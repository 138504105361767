import React, { Component } from 'react'
import styles from "./Header.module.css";
// import { RiAlarmFill } from "react-icons/ri";
import { RiShoppingCartLine } from "react-icons/ri";
import OtpInput from 'react-otp-input';

import logo from '../../assets/images/drome_logo.png'
import brandBgNav from '../../assets/images/brand_bg_nav.svg'
import glogo from '../../assets/images/glogo.svg'
import flogo from '../../assets/images/fblogo.svg'

import ltablet from '../../assets/images/ltablet.svg'
import rtablet from '../../assets/images/rtablet.svg'
import editnum from '../../assets/images/editnum.svg'
import rchevron from '../../assets/images/rchevron.svg'
import profile from '../../assets/images/profile.svg'
import logout from '../../assets/images/logout.svg'


import { getCart, login, mobileOTP, socialLogin, userLogout, verifyMobileOTP } from '../../apis';

import firebase from "firebase/app";
import "firebase/auth";
import cx from "classnames";
export class Header extends Component {

    state = {
        showLoginModal: false,
        showOTPModal: false,
        phone: "",
        otp: "",
        signedIn: false,
        verify_id: "",
        cartCount: 0
    } 

    openOTPModal = async() => {
        var data = await mobileOTP({mobile: this.state.phone});
        console.log(data)
        this.setState({verify_id: JSON.parse(data).verify_id});
        this.setState ({
            showLoginModal: false,
            showOTPModal: true
        });
    }

    openSignUpModal = () => {
        this.setState ({
            showLoginModal: true,
            showOTPModal: false
        });
    }

    changePhone = (event) => {
        event.preventDefault();
        console.log(event.target.value)
        this.setState({phone: event.target.value});
    }

    verifyOTP = async() => {
        var data = await verifyMobileOTP(this.state.otp, this.state.verify_id);
        console.log(data)
        this.signIn();
        this.hideModals();
    }

    hideModals = async(e) => {
        // e.preventDefault();
        var data = JSON.parse(localStorage.getItem('user'));
        console.log('USER LOCAL SRTO')
        console.log(data)
        if(data) {
            this.setState({signedIn: true})
        }
        this.setState ({
            showLoginModal: false,
            showOTPModal: false
        });
        window.location.href = '/';
    }

    googleSignIn = () => {
        var provider = new firebase.auth.GoogleAuthProvider();
        //provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
        var dta;
        firebase.auth().signInWithPopup(provider).then(async(result) => {
            var token = result.credential.idToken;
            var user = result.additionalUserInfo.profile; // result.user;
            console.log("GOOGLE");
            console.log(token);
            //console.log(user)
            let data = {
                first_name: user.given_name,
                last_name: user.family_name,
                email: user.email,
                auth_token: token,
                auth_type: "google",
                social_id: user.id,
                img_url: user.picture
            }
            dta = await socialLogin(data);
            console.log("DADADA");
            console.log(dta);
            this.hideModals();
          }).catch((error) => {
            this.hideModals();
          });
    }

    fbSignIn = () => {
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then(async(result) => {
            var token = result.credential.accessToken;
            var user = result.additionalUserInfo.profile; // result.user;
            console.log("FB")
            console.log(token)
            console.log(user)
            var data = {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                auth_token: token,
                auth_type: "facebook",
                social_id: user.id,
                img_url: user.picture.data.url
            }
            var dta = await socialLogin(data);
            console.log("DADADA");
            console.log(dta);
            this.hideModals();
          }).catch((error) => {
            console.log("Encountered error in Facebook login");
          });
          this.hideModals();
    }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            console.log(localStorage.getItem('user'));
            this.setState({signedIn: true});
        }
        // this.getCartItems();
        
    }

    signIn = async() => {
        await login();
    }

    logout = async() => {
        await userLogout();
        this.setState ({
            signedIn: false
        });
        localStorage.removeItem('user');
        window.location.href = '/';
    }

    getCartItems = async() => {
        var data = await getCart();
        console.log("HEADER CART COUNT")
        console.log(data)
        if (data && data.total_items) {
            this.setState({cartCount: data.total_items});
        }
    }

    showLoginModal = () => {
        if (this.state.showLoginModal) {
            return (
                <div className={styles.loginModalBg} onClick={() => {
                    this.setState({showLoginModal : false})
                }}>
                    <div className={styles.loginModal} onClick={(e) => {
                        e.stopPropagation();
                    }}>
                        <img src={logo} fluid className={styles.loginLogo} alt="logo"/>
                        <div className={styles.modalTitle}>We are ready for you</div>
                        <div className={styles.phoneNumberDiv}>
                            <div>
                                <input className={styles.phoneNum} type="number" placeholder="Enter phone number" value={this.state.phone} onChange={(phone) => this.changePhone(phone)} />
                            </div>
                            <div onClick={() => this.openOTPModal()} className={styles.sendOTP}>Send OTP</div>
                        </div>
                        <div className={styles.or}>or</div>
                        <div onClick={() => this.googleSignIn()} className={styles.socialButtonG}>
                            <div className={styles.socialLogo}>
                                <img src={glogo} fluid className={styles.slogo} alt="glogo"/>
                            </div>
                            <div className={styles.signupText}>Sign up using Gmail</div>
                        </div>
                        <div onClick={() => this.fbSignIn()} className={styles.socialButtonF}>
                            <div className={styles.socialLogo}>
                                <img src={flogo} fluid className={styles.slogo} alt="flogo"/>
                            </div>
                            <div className={styles.signupText}>Sign up using Facebook</div>
                        </div>
                        {/* <div onClick={() => this.hideModals()} className={styles.socialButtonE}>
                            <div className={styles.socialLogo}>
                                <img src={elogo} fluid className={styles.slogo} alt="elogo"/>
                            </div>
                            <div className={styles.signupText}>Sign up using Email</div>
                        </div> */}
                        <img src={ltablet} alt="ltablet" fluid className={styles.ltablet}/>
                        <img src={rtablet} alt="rtablet" fluid className={styles.rtablet}/>
                    </div>
                </div>
            )
        }
    }

    showOTPModal = () => {
        if (this.state.showOTPModal) {
            return (
                <div className={styles.loginModalBg}>
                    <div className={styles.loginModal}>
                        <img src={logo} fluid className={styles.loginLogo} alt="logo"/>
                        <div className={styles.modalTitle}>We have sent a verification code to</div>
                        <div className={styles.phoneNumberDivOTP}>
                            <div className={styles.phoneNumOTP}>{this.state.phone}</div>
                            <img onClick={() => this.openSignUpModal()} src={editnum} fluid className={styles.editnum} alt="editnum"/>
                        </div>

                        <div className={styles.otpDiv}>
                            <OtpInput
                                value={this.state.otp}
                                onChange={otp => this.setState({ otp })}
                                numInputs={4}
                                isInputNum={true}
                                separator={<span style={{marginRight: 20}}></span>}
                                containerStyle={{}}
                                inputStyle={{borderRadius: "50%", border: "2px solid #2EAFA3", width: 40, height: 40}}
                            />
                            <div onClick={() => this.verifyOTP()} className={styles.otpsubmit}>
                                <img src={rchevron} fluid className={styles.rchevron} alt="rchevron"/>
                            </div>
                        </div>
                        <div onClick={() => this.openOTPModal()} className={styles.resendOTPDiv}>Resend OTP</div>
                        
                        <img src={ltablet} fluid className={styles.ltabletO} alt="ltablet"/>
                        <img src={rtablet} fluid className={styles.rtabletO} alt="rtablet"/>
                    </div>
                </div>
            )
        }
    }

    toCart = () => {
        window.location.href = "/cart";
    }

    render() {
        console.log("props" , this.props);
        return (
            <div className={styles.headerContainer}>
                <div className={styles.brand} onClick={() => {
                    window.location = "/"
                }}>
                    <img src={logo} fluid className={styles.logo} alt="logo"/>
                    <img src={brandBgNav} fluid className={styles.brandBgNav} alt="brandBgNav"/>
                </div>
                <div className={styles.menuContainer}>
                    <div className={styles.menuItem}><a href="https://drome.co.in" target="_blank" rel="noreferrer" className={styles.ourStory}>Home</a></div>
                    {/* <div className={styles.menuItem}><a href="https://drome.co.in" target="_blank" rel="noreferrer" className={styles.ourStory}>Our Story</a></div> */}
                    {/* <div className={styles.menuItem}>Blog</div> */}
                    {/* <a href="/" className={styles.pharmacyMenuItem}>Pharmacy</a> */}
                    {/* <a href="/" className={styles.pharmacyMenuItem}>Cart</a> */}
                    <div className={cx("hFlex" , styles.cartContainer)} onClick={() => {
                        window.location = "/cart";
                    }}>
                        <div className={cx(styles.marginRight)}>Cart</div>
                        <RiShoppingCartLine size="1.5em"/>
                            {
                                this.props.cartCount > 0 ?
                                <div className={styles.cartCount}>{this.props.cartCount}</div> : 
                                ""
                            }
                    </div>
                    <div onClick={() => this.toCart()}  className={styles.cover} />
                    {/* <div className={styles.cartIconItem}>
                        <RiShoppingCartLine size="1.5em"/>
                        {
                            this.props.cartCount > 0 ?
                            <div className={styles.cartCount}>{this.props.cartCount}</div> : 
                            ""
                        }
                    </div> */}
                    {/* <div className={styles.covidCareDiv}>
                        <div className={styles.covidCareMenuItem}>Covid Care</div>
                        <RiAlarmFill size="1.5em" className={styles.alarm} />
                        <div className={styles.comingSoon}>*Coming Soon</div>
                    </div> */}
                    {
                        !this.state.signedIn ? 
                        <div onClick={() => {this.openSignUpModal()}} className={styles.signin}>Sign in</div> :
                        <div className={styles.signin}>
                            <img onClick={() => {window.location.href = "/profile"}} src={profile} fluid className={styles.profile} alt="profile"/>
                            <img src={logout} onClick={() => this.logout()} fluid className={styles.logout} alt="logout"/>
                        </div>
                    }
                </div>
                {this.showLoginModal()}
                {this.showOTPModal()}
            </div>
        )
    }
}

export default Header
