import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

import styles from './Profile.module.css'

import back from '../../assets/images/back.svg'
import editnum from '../../assets/images/editnum.svg'
import profile2 from '../../assets/images/profile2.svg'
import { getUser, postUser } from '../../apis'

export class Profile extends Component {

    state = {
        name: "",
        phone: "",
        email: "",
        pincode: "",
        address: "",
        editMode: false
    }

    saveDetails = async() => {
        var data = await this.postUser();
        console.log("PROFILE SAVE");
        console.log(data);
        this.setState({editMode: false});
    }

    componentDidMount() {
        // this.getUser();
        if (localStorage.getItem('user')) {
            var user = JSON.parse(localStorage.getItem('user'));
            console.log('LOCAL USER')
            console.log(user);
            this.setState({
                name: user.first_name,
                phone: user.mobile,
                email: user.email,
            });
            if (user.addresses.length>0) {
                this.setState({
                    pincode: user.addresses[0].pincode,
                    address: user.addresses[0].address_line1,
                })
            }
        }
    }

    getUser = async() => {
        var data = await getUser();
        console.log("USER")
        this.setState({
            email: data.email, 
            name: data.first_name + " " + data.last_name, 
            phone: data.mobile})
        if (data.addresses) {
            this.setState({
                pincode: data.addresses[0].pincode,
                address: data.addresses[0].address_line1,
            })
            }
        console.log(data)
    }

    postUser = async() => {

        if (this.state.pincode.length !== 6 && isNaN(this.state.pincode)) {
            alert("Pincode must be 6 digits")
        } else if (this.state.name && this.state.email && this.state.phone && this.state.pincode && this.state.address) {
            var user = {
                "email" : this.state.email,
                "mobile" : this.state.phone,
                "first_name": this.state.name,
                "last_name": "",
                "addresses": [{
                    "pincode": this.state.pincode,
                    "address_line1": this.state.address,
                    "city": "Default"
                }]
            }
    
            var data = await postUser(user);
            console.log("POST USER")
            console.log(data);
        } else {
            alert('Please fill all the info');
        }
        
    }

    render() {
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                    <Link to="/" className={styles.back}>
                        <img src={back} fluid className={styles.backicon} alt="back"/>
                    </Link>

                    <div className={styles.innerBody}>
                        <div className={styles.left}>
                            <div className={styles.lTop}>
                                <div className={styles.lTopTitle}>Your<br/>Profile</div>
                                <div className={styles.profile}>
                                    <img src={profile2} fluid className={styles.profile2} alt="profile2"/>
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            {
                                !this.state.editMode ? 
                                <div onClick={() => this.setState({editMode: true})} className={styles.edit}>
                                    <div className={styles.editText}>Edit</div>
                                    <img src={editnum} fluid className={styles.editnum} alt="editnum"/>
                                </div> : <div style={{height: 20}} />
                            }

                            <div className={styles.details}>
                                <div className={styles.row}>
                                    <div className={styles.rowLeft}>Name</div>
                                    {
                                        !this.state.editMode ?
                                        <div className={styles.rowRight}>{this.state.name}</div> :
                                        <input className={styles.nameI} type="text" placeholder="Name" value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} />
                                    }
                                </div>
                                <div className={styles.row}> 
                                    <div className={styles.rowLeft}>Mobile</div>
                                    {
                                        !this.state.editMode ?
                                        <div className={styles.rowRight}>{this.state.phone}</div> :
                                        <input className={styles.phoneI} type="phone" placeholder="Mobile No." value={this.state.phone} onChange={(event) => this.setState({phone: event.target.value})} />
                                    }
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.rowLeft}>Email</div>
                                    {
                                        !this.state.editMode ?
                                        <div className={styles.rowRight}>{this.state.email}</div> :
                                        <input className={styles.emailI} type="email" placeholder="Email ID" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} />
                                    }
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.rowLeft}>Address</div>
                                    {
                                        !this.state.editMode ?
                                        <div className={styles.addDiv}>
                                            <div className={styles.pincode}>{this.state.pincode}</div>
                                            <div className={styles.rowRight}>{this.state.address}</div>
                                        </div> :
                                        <div className={styles.addOuterDiv}>
                                            <div className={styles.pinDiv}> 
                                                <input className={styles.pincodeI} type="text" placeholder="Pincode" value={this.state.pincode} onChange={(event) => this.setState({pincode: event.target.value})} />
                                            </div>
                                            <div className={styles.addDiv}>
                                                <input className={styles.addressI} type="text" placeholder="Address" value={this.state.address} onChange={(event) => this.setState({address: event.target.value})} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            {
                                this.state.editMode ? 
                                <div className={styles.saveDiv}>
                                    <div onClick={() => this.saveDetails()} className={styles.save}>Save</div>
                                </div> : 
                                <div className={styles.blankDiv}> </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Profile)
