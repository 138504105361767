import React, { Component } from 'react';
import styles from './PaymentDetails.module.css';



import CartItem from '../../components/CartItem/CartItem';
import { paymentInfo, getOrderById, payForOrder , removePromoCodeForOrder , applyPromoCodeForOrder } from '../../apis';

import ltablet from '../../assets/images/ltablet.svg'
import rtablet from '../../assets/images/rtablet.svg'
import cross from '../../assets/images/cross.svg'
import cx from "classnames";

export class PaymentDetails extends Component {
    state = {
        itemTotal: 0,
        discount: 200,
        shippingfee: 50,
        items: [],
        isPrescriptionDrugInCart: false,
        cart: {
            promo_code: null,
            promotion_amount: null,
            promotion_description:null,
        },
        promo: '',
        promoApplied: null,
        showConfirmPopup: false,
        orderData: '',
        paymentResponse: ''
    }

    componentDidMount() {
        this.getApprovedCart();
    }

    getApprovedCart = async() => {
        //GET APPROVED CART
        var data = await getOrderById(this.props.location.state.orderid);
        console.log("APPROVED ORDER");
        console.log(data);
        if (data && data.orderitem) {
            this.setState({cart: data, items: data.orderitem}); 
            if(data.promo_code)
            {
                this.setState({promo : data.promo_code , promoApplied : true});
            }
        } 
        this.calculateItemTotal();
    }
    applyPromoCode = async () => {
       
        try{
            if(this.state.promo){
                let data = await applyPromoCodeForOrder(this.state.promo , this.props.location.state.orderid);
                this.setState({cart: data.order, items: data.order.orderitem}); 
                // await this.setState({promotionResponse : data})
                this.setState({promo : data.order.promo_code , promoApplied : data.order.promo_code ? true : false});
            }
        }
        catch(e)
        {
            console.log(e);
        }
    }
    removePromoCode = async () => {
        
        try{
            let data = await removePromoCodeForOrder(this.props.location.state.orderid);
            this.setState({cart: data.order, items: data.order.orderitem}); 
            this.setState({promo : "" , promoApplied : false});
        }
        catch(e)
        {
            console.log(e);
        }
    }
    checkout = async() => {
        // NAVIGATE TO PAYMENT GATEWAY
        var data = await payForOrder(this.props.location.state.orderid);
        this.setState({orderData: data})
        console.log("Order Data")
        console.log(data)
        this.setState({showConfirmPopup: true})
    }

    proceedToPay = async () => {
        var options = {
            "key": process.env.REACT_APP_RAZORPAY_KEY,
            "amount": this.state.orderData.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "Drome",
            "description": "Drome Transaction",
            "image": "public/favicon.ico",
            "order_id": this.state.orderData.orderId,
            "handler": async (response) => {
                console.log(response)
                var data = await paymentInfo({"paymentId": this.state.orderData.paymentId, "response": JSON.stringify(response)});
                console.log(this.state.orderData.paymentId)
                console.log("PAYMENT SUCCESSFUL HANDLER")
                console.log(data)
                window.location.href = '/order-placed?orderId=' + data.entity_id;
            },
            // "callback_url": process.env.REACT_APP_API_BASE_URL+'payments/'+this.state.orderData.paymentId+'/',
            "prefill": {
                "name": this.state.orderData.data.user.first_name,
                "email": this.state.orderData.data.user.email,
                "contact": "9999999999"
            }
        }
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
    }

    cod = () => {
        // COD API CALL
        window.location.href = '/order-placed';
    }

    calculateItemTotal = () => {
        var total = 0;
        for(var i=0; i<this.state.items.length; i++) {
            total += this.state.items[i].pricePerItem*this.state.items[i].quantity;
        }
        this.setState({itemTotal: total});
    }

    minusQuantity = (item) => {
        var elementsIndex = this.state.items.findIndex(element => element.id === item.id);
        let newArray = [...this.state.items];

        var newItemTotal = this.state.itemTotal
        if (newArray[elementsIndex].quantity > 0) {
            newArray[elementsIndex] = {...newArray[elementsIndex], quantity: newArray[elementsIndex].quantity-1}
            newItemTotal = this.state.itemTotal-newArray[elementsIndex].pricePerItem;
        }

        this.setState({
            items: newArray,
            itemTotal: newItemTotal
        });

    }

    plusQuantity = (item) => {
        var elementsIndex = this.state.items.findIndex(element => element.id === item.id);
        let newArray = [...this.state.items];

        newArray[elementsIndex] = {...newArray[elementsIndex], quantity: newArray[elementsIndex].quantity+1}

        this.setState({
            items: newArray,
            itemTotal: this.state.itemTotal+newArray[elementsIndex].pricePerItem
        });
    }

    showConfirmPopup = () => {
        if (this.state.showConfirmPopup) {
            return (
                <div className={styles.loginModalBg}>
                    <div className={styles.loginModal}>
                        <div onClick={() => this.setState({showConfirmPopup: false})}>
                            <img src={cross} fluid className={styles.cross} alt="cross"/>
                        </div>
                        {/* <img src={logo} fluid className={styles.loginLogo} alt="logo"/> */}
                        <div className={styles.modalTitle}>We are re-directing you to our payment partner </div>
                        <div className={styles.modalSubTitle}>Please process to complete your order placement</div>
                        <div id="rzp-button1" onClick={() => this.proceedToPay()} className={styles.proceedToPay}>Proceed to Pay</div>
                        <img src={ltablet} alt="ltablet" fluid className={styles.ltablet}/>
                        <img src={rtablet} alt="rtablet" fluid className={styles.rtablet}/>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                
                <div className={styles.body}>
                    <div className={styles.headDiv}>
                        <div>
                            <div className={styles.title}>Approved<br/>Order</div>
                            <div className={styles.subtitle}>Your below order has been approved by pharmacy</div>
                        </div>
                        <div className={styles.dt}>Delivery timings: 9.00am to 9.00pm</div>
                    </div>
                    <div className={styles.cartBody}>
                        <div className={styles.leftDiv}>
                            <div className={styles.list}>
                                {
                                    this.state.items.length > 0 ?
                                    this.state.items.map(item => {
                                        return (
                                            <CartItem approved={true} item={item} plusQuantity={this.plusQuantity} minusQuantity={this.minusQuantity} />
                                        )
                                    }) : ""
                                }
                            </div>
                        </div>
                        <div className={styles.rightDiv}>
                        <div className={styles.summaryCard}>
                                <div className={styles.horDiv}>
                                    <div>Item Total (MRP)</div>
                                    <div className={styles.total}>{this.state.cart.amount} ₹</div>
                                </div>
                                <div className={styles.horDiv}> 
                                    <div>Price Discount</div>
                                    <div>-{this.state.cart.discount} ₹</div>
                                </div>
                                <div className={styles.shpDiv}>
                                    <div>Shipping Fee</div>
                                    <div>{this.state.cart.shipping_fee} ₹</div>
                                </div>
                                {this.state.cart.promotion_amount ? 
                                <div className={styles.shpDiv}>
                                    <div>Promotion amount</div>
                                    <div>-{this.state.cart.promotion_amount} ₹</div>
                                </div> : ""}
                                <div className={styles.tbpDiv}>
                                    <div>To be paid</div>
                                    <div className={styles.tbp}>{this.state.cart.final_amount} ₹</div>
                                </div>
                                <div className={styles.promoDiv}>
                                    <div className={styles.shpDiv}>
                                        <input className={cx(styles.promo , this.state.promoApplied && "disabled")} disabled={this.state.promoApplied} type="text" placeholder="Enter promo code" value={this.state.promo} onChange={(e) => this.setState({promo: e.target.value})} />
                                        <div className={cx(styles.tbp , "pointer")}>{this.state.cart.promo_code || this.state.promoApplied? <div onClick={async () => {
                                            await this.removePromoCode()
                                        }}>Clear</div> : <div onClick={async () => {
                                            await this.applyPromoCode()
                                        }}>Apply</div>}</div>
                                    </div>
                                </div>
                                {this.state.cart.promotion_description && <div className={cx(styles.promoDescription)}>
                                    {this.state.cart.promotion_description}
                                </div>}
                                <div className={styles.butDiv}>
                                    <div></div>
                                    <div onClick={() => this.checkout()} className={styles.button}>Checkout</div>
                                </div>
                            </div>
                            <div className={styles.lBottom}>
                                <div className={styles.lBottomTitle}>Having trouble placing your order?</div>
                                <div className={styles.lBottomSubtitle}>Call us right away!</div>
                                <div className={styles.phoneDiv}>
                                    <a className={styles.lBottomPhone} href="tel:+911204030499">+911204030499</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.showConfirmPopup()}
            </div>
        )
    }
}

export default PaymentDetails
