import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

import styles from './Status.module.css'

import back from '../../assets/images/back.svg'
import statusill from '../../assets/images/statusill.svg'
import tablet from '../../assets/images/rtablet.svg'
import prescription from '../../assets/images/prescription.svg'

export class Status extends Component {

    saveDetails = () => {

    }

    render() {
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                    <Link to="/" className={styles.back}>
                        <img src={back} fluid className={styles.backicon} alt="back"/>
                    </Link>

                    <div className={styles.innerBody}>
                        <div className={styles.left}>
                            <div className={styles.lTop}>
                                <div className={styles.lTopTitle}>Approval<br/>Pending</div>
                                <div className={styles.lTopSubtitle}>Your order has been submitted. Please wait for it to be approved</div>

                                <div className={styles.profile}>
                                    <img src={prescription} fluid className={styles.profile2} alt="prescription"/>
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.text}>Orders usually take few hours to get approved,<br/>We shall keep you informed!</div>
                            <img src={statusill} fluid className={styles.statusill} alt="statusill"/>
                            <div className={styles.submitted}>Submitted</div>
                            <a href="/" className={styles.done}>Done</a>
                            <img src={tablet} fluid className={styles.tablet} alt="tablet"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Status)
