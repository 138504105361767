import React, { Component } from 'react';
import styles from './Cart.module.css';



import CartItem from '../../components/CartItem/CartItem';
import { editCartQuantity, getCart, createOrder , applyPromoCode , removePromoCode} from '../../apis';

import cx from "classnames";

export class Cart extends Component {
    state = {
        itemTotal: 0,
        discount: 200,
        shippingfee: 50,
        items: [
        // {
        //     id: 1,
        //     name: 'Allegra 20mg',
        //     description : 'Quantity 2 strips (1x6 Tablets)',
        //     quantity: 2,
        //     isPrescriptionDrug: true,
        //     pricePerItem: 150,
        // }, {
        //     id: 2,
        //     name: 'Allegra 20mg',
        //     description : 'Quantity 2 strips (1x6 Tablets)',
        //     quantity: 4,
        //     isPrescriptionDrug: false,
        //     pricePerItem: 100,
        // }, 
        ],
        isPrescriptionDrugInCart: true,
        cart: {
            promo_code: null,
            promotion_amount: null,
            promotion_description:null,
        },
        promo: "",
        promoApplied : null,
        // promotionResponse :{
        //     cart : null,
        //     promotion_amount: null,
        //     promotion_message: null,
        //     status : null,
        // }
    }

    componentDidMount() {
        this.getCart();
    }

    getCart = async() => {
        var data = await getCart();
        if (data && data.cartitem) {
            this.setState({cart: data, items: data.cartitem}); 
            // setting the initial promo_code
            if(data.promo_code)
            {
                this.setState({promo : data.promo_code , promoApplied : true});
            }
            
        } 
        this.calculateItemTotal();
    }
    applyPromoCode = async () => {
       
        try{
            if(this.state.promo){
                let data = await applyPromoCode(this.state.promo);
                this.setState({cart: data.cart, items: data.cart.cartitem}); 
                // await this.setState({promotionResponse : data})
                this.setState({promo : data.cart.promo_code , promoApplied : data.cart.promo_code ? true : false});
            }
        }
        catch(e)
        {
            console.log(e);
        }
    }
    removePromoCode = async () => {
        
        try{
            let data = await removePromoCode(this.state.promo);
            this.setState({cart: data.cart, items: data.cart.cartitem}); 
            this.setState({promo : "" , promoApplied : false});
        }
        catch(e)
        {
            console.log(e);
        }
    }
    checkout = async () => {
        if (localStorage.getItem('user')) {
            // if(this.state.promo){
            //     await applyPromoCode(this.state.promo);
            // }
            var data = await createOrder();
            if (data) {
                if (this.state.cart.has_scheduled_drug) {
                    window.location.href = `/prescription-upload?id=${data.id}`;
                } else {
                    window.location.href = `/delivery-details?id=${data.id}`;
                }
            }
        } else {
            alert('Please sign in first!')
        }
    }

    calculateItemTotal = () => {
        var total = 0;
        for(var i=0; i<this.state.items.length; i++) {
            total += parseFloat(this.state.items[i].price)*this.state.items[i].quantity;
        }
        this.setState({itemTotal: total});
    }

    minusQuantity = async(item) => {
        var elementsIndex = this.state.items.findIndex(element => element.id === item.id);
        let newArray = [...this.state.items];

        var newItemTotal = this.state.itemTotal
        if (newArray[elementsIndex].quantity > 0) {
            newArray[elementsIndex] = {...newArray[elementsIndex], quantity: newArray[elementsIndex].quantity-1}
            newItemTotal = parseFloat(this.state.itemTotal-newArray[elementsIndex].price);
        }
        try{
            var data = await editCartQuantity(item.id, item.quantity-1>=0 ? item.quantity-1 : 0);
            this.setState({cart: data.cart, items: data.cart.cartitem});
            this.setState({
                items: newArray,
                itemTotal: newItemTotal
            });
        }
        catch(err)
        {
            console.log(err);
        }
        
    }

    plusQuantity = async(item) => {
        var elementsIndex = this.state.items.findIndex(element => element.id === item.id);
        let newArray = [...this.state.items];

        newArray[elementsIndex] = {...newArray[elementsIndex], quantity: newArray[elementsIndex].quantity+1}
        try{
            var data = await editCartQuantity(item.id, item.quantity+1);
            this.setState({cart: data.cart, items: data.cart.cartitem});
            this.setState({
                items: newArray,
                itemTotal: this.state.itemTotal+parseFloat(newArray[elementsIndex].price)
            });
        }
        catch(err)
        {
            console.log(err);
        }
        
    }

    render() {
       
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                    <div className={styles.headDiv}>
                        <div>
                            <div className={styles.title}>Cart<br/>Details</div>
                            <div className={styles.subtitle}>Your order will be approved by pharmacy on checkout</div>
                        </div>
                        <div className={styles.dt}>Delivery timings: 9.00am to 9.00pm</div>
                    </div>
                    <div className={styles.cartBody}>
                        <div className={styles.leftDiv}>
                            <div className={styles.list}>
                                {   
                                    this.state.items.length > 0 ?
                                    this.state.items.map(item => {
                                        return (
                                            <CartItem approved={false} item={item} plusQuantity={this.plusQuantity} minusQuantity={this.minusQuantity} />
                                        )
                                    }) : ""
                                }
                            </div>
                        </div>
                        <div className={styles.rightDiv}>
                            <div className={styles.summaryCard}>
                                <div className={styles.horDiv}>
                                    <div>Item Total (MRP)</div>
                                    <div className={styles.total}>{this.state.cart.amount} ₹</div>
                                </div>
                                <div className={styles.horDiv}> 
                                    <div>Price Discount</div>
                                    <div>-{this.state.cart.discount} ₹</div>
                                </div>
                                <div className={styles.shpDiv}>
                                    <div>Shipping Fee</div>
                                    <div>{this.state.cart.shipping_fee} ₹</div>
                                </div>
                                {this.state.cart.promotion_amount ? 
                                <div className={styles.shpDiv}>
                                    <div>Promotion amount</div>
                                    <div>-{this.state.cart.promotion_amount} ₹</div>
                                </div> : ""}
                                <div className={styles.tbpDiv}>
                                    <div>To be paid</div>
                                    <div className={styles.tbp}>{this.state.cart.final_amount} ₹</div>
                                </div>
                                <div className={styles.promoDiv}>
                                    <div className={styles.shpDiv}>
                                        <input className={cx(styles.promo , this.state.promoApplied && "disabled")} disabled={this.state.promoApplied} type="text" placeholder="Enter promo code" value={this.state.promo} onChange={(e) => this.setState({promo: e.target.value})} />
                                        <div className={cx(styles.tbp , "pointer")}>{this.state.cart.promo_code || this.state.promoApplied? <div onClick={async () => {
                                            await this.removePromoCode()
                                        }}>Clear</div> : <div onClick={async () => {
                                            await this.applyPromoCode()
                                        }}>Apply</div>}</div>
                                    </div>
                                </div>
                                {this.state.cart.promotion_description && <div className={cx(styles.promoDescription)}>
                                    {this.state.cart.promotion_description}
                                </div>}
                                <div className={styles.butDiv}>
                                    {/* <div>
                                        <input className={styles.promo} type="text" placeholder="Promo code" value={this.state.promo} onChange={(event) => this.setState({promo: event.target.value})} />
                                    </div> */}
                                    {
                                        this.state.items.length < 1 ? 
                                        <div style={{opacity: 0.6, cursor: 'not-allowed'}} className={styles.button}>Checkout</div> :
                                        <div onClick={() => this.checkout()} className={styles.button}>Checkout</div>
                                    }
                                </div>
                            </div>
                            <div className={styles.lBottom}>
                                <div className={styles.lBottomTitle}>Having trouble placing your order?</div>
                                <div className={styles.lBottomSubtitle}>Call us right away!</div>
                                <div className={styles.phoneDiv}>
                                    <a className={styles.lBottomPhone} href="tel:+911204030499">+911204030499</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Cart
