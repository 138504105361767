import React, { Component } from 'react'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';


import styles from './PrescriptionUpload.module.css';

import back from '../../assets/images/back.svg'
import cross from '../../assets/images/cross.svg'
import bcross from '../../assets/images/bcross.svg'
import dragndrop from '../../assets/images/dragndrop.svg'
import dragndropsuccessful from '../../assets/images/dragndropsuccessful.svg'
import { Link, withRouter } from 'react-router-dom';

export class PrescriptionUpload extends Component {

    dropRef = React.createRef()

    state = {
        dragging: false,
        dragimg: dragndrop,
        selectedFile: null,
        files: [],
        camPhoto: "",
        showCam: false,
        email: '',
        phone: '',
        pincode: '',
        address: '',
        orderid: ''
    }

    navigateToStatus = () => {
        window.location.href = `/status`;
    }

    removeSelectedImage = () => {
        this.setState({
            selectedFile: null,
            files: [],
            camPhoto: "",
            showCam: false,
            dragimg: dragndrop
        })
    }

    handleTakePhoto = (dataUri) => {
        this.setState({camPhoto: dataUri, selectedFile: dataUri, showCam: false, dragimg: dragndropsuccessful});
    }

    showCam = (e) => {
        e.preventDefault();
        this.setState({showCam: true})
    }

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++  
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true})
        }
    }
    
    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--
        if (this.dragCounter > 0) return
        this.setState({dragging: false})
    }
    
    handleDrop = (e) => {    
        e.preventDefault()
        e.stopPropagation()
        this.setState({drag: false})
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.handleDropFile(e.dataTransfer.files)
            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    }

    handleDropFile = (files) => {
        let fileList = this.state.files
        for (var i = 0; i < files.length; i++) {
          if (!files[i].name) return
          fileList.push(files[i].name)
        }
        this.setState({files: fileList, selectedFile: files[0], dragimg: dragndropsuccessful})
      }

    componentDidMount() {
        if (localStorage.getItem('user')) {
            this.dragCounter = 0
            let div = this.dropRef.current
            div.addEventListener('dragenter', this.handleDragIn)
            div.addEventListener('dragleave', this.handleDragOut)
            div.addEventListener('dragover', this.handleDrag)
            div.addEventListener('drop', this.handleDrop)

            const search = this.props.location.search; 
            const params = new URLSearchParams(search); 
            const IdFromURL = params.get('id'); 
            this.setState({orderid: IdFromURL});
        } else {
            alert('Please sign in first');
            window.location.href = "/";
        }
    }

    componentWillUnmount() {
        let div = this.dropRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
    }

    onFileChange = event => { 
        // Update the state 
        event.preventDefault();
        this.setState({ selectedFile: event.target.files[0], files: event.target.files, dragimg: dragndropsuccessful}); 
       
      }; 

    onCamFileUpload = async() => {
        console.log("CAM PHOTO UPLOAD")
        // console.log(this.state.selectedFile); 
       
        var data;
        let testData = JSON.stringify({
            prescription: this.state.selectedFile
        });
        // testData.append('prescription', this.state.selectedFile, this.state.selectedFile.name);
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: myHeaders,
            body: testData,
            redirect: 'follow'
        };

        await fetch(process.env.REACT_APP_API_BASE_URL + 'users/prescription/upload/', requestOptions)
            .then(response => response.text())
            .then(result => {
                data = result
                console.log("PRESCRIPTION CAM UPLOAD PROCEED")
                console.log(data);
                window.location.href = `/delivery-details`;
            })
            .catch(error => {
                alert('Something went wrong. Please try again')
                console.log('error', error)
            });
    }

    onFileUpload = async() => { 
       
        // Details of the uploaded file 
        console.log(this.state.selectedFile); 
       
        var data;
        let testData = new FormData();
        testData.append('prescription', this.state.selectedFile, this.state.selectedFile.name);
        
        var myHeaders = new Headers();

        var requestOptions = {
            method: 'POST',
            credentials: 'include',
            headers: myHeaders,
            body: testData,
            redirect: 'follow'
        };

        await fetch(process.env.REACT_APP_API_BASE_URL + 'users/prescription/upload/', requestOptions)
            .then(response => response.text())
            .then(result => {
                data = result
                console.log("PRESCRIPTION UPLOAD PROCEED")
                console.log(data);
                window.location.href = `/delivery-details`;
            })
            .catch(error => {
                alert('Something went wrong. Please try again')
                console.log('error', error)
            });

        // Request made to the backend api 
        // Send formData object 
        // axios.post("api/uploadfile", formData); 
    }; 
    

    render() {
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                   <div className={styles.left}>
                        <div className={this.state.dragimg === dragndrop ? styles.innerDiv : styles.innerDivTick}>
                            <div className={styles.crossDiv}>
                                <Link to="/cart" className={styles.back}>
                                    <img src={back} fluid className={styles.backicon} alt="back"/>
                                </Link>
                                <Link to="/" className={styles.back}>
                                    <img src={cross} fluid className={styles.cross} alt="cross"/>
                                </Link>
                            </div>
                            <div className={styles.uploadDiv} ref={this.dropRef}>
                                <div className={styles.uploadTitleDiv}>
                                    <div className={styles.uploadTitle}>Upload Prescription</div>
                                </div>
                                <div className={styles.uploadImageDiv}>
                                    <img src={this.state.dragimg} fluid className={styles.dragndrop} alt="dragimg"/>
                                </div>
                                <div className={styles.browseButtonDiv}>
                                    <form method="post" enctype="multipart/form-data" action="https://api.drome.app/users/prescription/upload/">
                                        {
                                            this.state.selectedFile ?
                                            <div className={styles.proceedDiv}>
                                                <div className={styles.filenamediv}>
                                                    <img src={bcross} alt="bcross" onClick={() => {this.removeSelectedImage()}} fluid className={styles.bcross}/>
                                                    <div className={styles.filename}>{this.state.camPhoto ? "Clicked Photo" : this.state.selectedFile.name}</div>
                                                </div>
                                               {
                                                   this.state.camPhoto ? 
                                                   <div style={{cursor: 'pointer'}} type="submit" onClick={() => this.onCamFileUpload()} className={styles.proceed}>Proceed</div> :
                                                   <div style={{cursor: 'pointer'}} type="submit" onClick={() => this.onFileUpload()} className={styles.proceed}>Proceed</div>
                                               } 
                                                {/* href={`/delivery-details?id=${this.state.orderid}`} */}
                                            </div> : 
                                            <div className={styles.browseDiv}>
                                                <div className={styles.browseButton}>
                                                    <input name="prescription" type="file" onChange={(e) => this.onFileChange(e)}></input> 
                                                    Browse
                                                </div>
                                                <div className={styles.orDiv}>or</div>
                                                <div onClick={(e) => this.showCam(e)} className={styles.takePicButton}>
                                                {/* <div onClick={(e) => alert('Feature under development!')} className={styles.takePicButton}> */}
                                                    Take a picture
                                                </div>
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                            <div className={styles.btm}>
                                <div className={styles.bottomCall}>Trouble uploading a prescription? <a href="tel:+911204030499" className={styles.number}>Call us on +91 992567890</a></div>
                            </div>
                        </div>
                   </div>
                   {/* <div className={styles.right}>
                        <div className={styles.rightTitleDiv}>
                            <div className={styles.rightTitle}>Delivery<br />Date</div>
                            <img src={deliveryill} fluid className={styles.deliveryill}/>
                        </div>
                        <div className={styles.detailsDiv}>
                            <div className={styles.yourDetailsText}>Your Details</div>
                            <div>
                                <input className={styles.email} type="text" placeholder="Email Id" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} />
                            </div>
                            <div>
                                <input className={styles.phone} type="text" placeholder="Mobile No." value={this.state.phone} onChange={(event) => this.setState({phone: event.target.value})} />
                            </div>
                        </div>
                        <div className={styles.detailsDiv}>
                            <div className={styles.yourDetailsText}>Address</div>
                            <div className={styles.pinDiv}> 
                                <input className={styles.pincode} type="text" placeholder="Pincode" value={this.state.pincode} onChange={(event) => this.setState({pincode: event.target.value})} />
                            </div>
                            <div className={styles.addDiv}>
                                <input className={styles.address} type="text" placeholder="Address" value={this.state.address} onChange={(event) => this.setState({address: event.target.value})} />
                            </div>
                        </div>
                        <div className={styles.bookDiv}>
                            <div onClick={() => this.navigateToStatus()} className={styles.book}>Book</div>
                        </div>
                   </div> */}
                </div>
                {
                    this.state.showCam ? 
                    <div onClick={() => this.setState({showCam: false})} className={styles.camDiv}>
                        <Camera
                            onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
                            idealFacingMode = {FACING_MODES.ENVIRONMENT}
                            imageType = {IMAGE_TYPES.PNG}
                        />
                    </div> : ""
                }
            </div>
        )
    }
}

export default withRouter(PrescriptionUpload)
