import React, { Component } from 'react'
import styles from './FixedFooter.module.css'

import whatsapp from '../../assets/images/whatsapp.png'
import facebook from '../../assets/images/facebook.png'
import linkedin from '../../assets/images/linkedin.png'

export class FixedFooter extends Component {
    render() {
        return (
            <div className={styles.fixedfooter}>
                <div className={styles.textblock23}>Call Back Service <a href="tel:+911204030499" className={styles.link}>+911204030499</a>
                </div>
                <div className={styles.divblock22}>
                    <a href="https://www.facebook.com/dromefamilyphysician/" target="_blank" rel="noreferrer" className={styles.linkblock}><img src={facebook} loading="lazy"  className={styles.image11} alt="facebook"/></a>
                    <a href="https://www.linkedin.com/in/drome-family-physician/" target="_blank" rel="noreferrer" className={styles.linkblock2}><img src={linkedin} loading="lazy" className={styles.image12} alt="linkedin" /></a>
                    <a href="tel:+918800605704" className={styles.linkblock3}><img src={whatsapp} loading="lazy" className={styles.image13} alt="whatsapp"/></a>
                </div>
            </div>
        )
    }
}

export default FixedFooter
