import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

import styles from './DeliveryDetails.module.css'

import back from '../../assets/images/back.svg'
import tablet from '../../assets/images/rtablet.svg'
import prescription from '../../assets/images/prescription.svg'
import { getUser, postUser } from '../../apis'

export class DeliveryDetails extends Component {

    state = {
        fname: "",
        lname: "",
        phone: "",
        phone2: "",
        email: "",
        pincode: "",
        address: "",
        orderid: ""
    }

    componentDidMount() {
        this.getUser();
        const search = this.props.location.search; 
        const params = new URLSearchParams(search); 
        const IdFromURL = params.get('id'); 
        this.setState({orderid: IdFromURL});
    }

    getUser = async() => {
        var data = await getUser();
        console.log("USER")
        this.setState({
            email: data.email, 
            name: data.first_name + " " + data.last_name, 
            phone: data.mobile,
            phone2: data.alternate_mobile ? data.alternate_mobile : ''})
        if (data.addresses.length>0) {
            this.setState({
                pincode: data.addresses[0].pincode,
                address: data.addresses[0].address_line1,
            });
        }
        console.log(data)
    }

    postUser = async() => {
        var user = {
            "email" : this.state.email,
            "mobile" : this.state.phone,
            "alternate_mobile": this.state.phone2,
            "first_name": this.state.fname,
            "last_name": this.state.lname,
            "addresses": [{
                "pincode": this.state.pincode,
                "address_line1": this.state.address,
                "city": "Default"
            }]
        }

        var data = await postUser(user);
        console.log("POST USER")
        console.log(data);
    }

    navigateToStatus = async() => {
        if (this.state.name && this.state.address && this.state.email && this.state.phone) {
            await this.postUser();
            window.location.href = "/status";
        } else {
            alert("Please fill all the fields!");
        }
    }

    render() {
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                    <Link to="/cart" className={styles.back}>
                        <img src={back} fluid className={styles.backicon} alt="back"/>
                    </Link>

                    <div className={styles.innerBody}>
                        <div className={styles.left}>
                            <div className={styles.lTop}>
                                <div className={styles.lTopTitle}>Delivery<br/>Details</div>
                                <div className={styles.lTopSubtitle}>Your prescrition has been submitted. Please wait for it to be approved</div>
                                <div className={styles.profile}>
                                    <img src={prescription} fluid className={styles.prescription} alt="prescription"/>
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            {/* <div onClick={() => this.setState({editMode: true})} className={styles.edit}>
                                <img src={deliveryill} fluid className={styles.deliveryill}/>
                            </div>  */}

                            <div className={styles.detailsDiv}>
                                <div className={styles.yourDetailsText}>Name</div>
                                <div className={styles.add}>
                                    <input className={styles.name} type="text" placeholder="First Name" value={this.state.fname} onChange={(event) => this.setState({fname: event.target.value})} />
                                    <input className={styles.name} type="text" placeholder="Last Name" value={this.state.lname} onChange={(event) => this.setState({lname: event.target.value})} />
                                </div>
                            </div>
                            <div className={styles.detailsDiv}>
                                <div className={styles.yourDetailsText}>Email</div>
                                <div className={styles.add}>
                                    <input className={styles.email} type="text" placeholder="Email ID" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} />
                                </div>
                            </div>
                            <div className={styles.detailsDiv}>
                                <div className={styles.yourDetailsText}>Contact</div>
                                <div className={styles.add}>
                                    <input className={styles.phone} type="text" placeholder="Mobile No." value={this.state.phone} onChange={(event) => this.setState({phone: event.target.value})} />
                                    <input className={styles.phone} type="text" placeholder="Alternate No." value={this.state.phone2} onChange={(event) => this.setState({phone2: event.target.value})} />
                                </div>
                            </div>
                            <div className={styles.detailsDiv}>
                                <div className={styles.yourDetailsText}>Address</div>
                                <div className={styles.addI}>
                                    <div className={styles.pinDiv}> 
                                        <input className={styles.pincode} type="text" placeholder="Pincode" value={this.state.pincode} onChange={(event) => this.setState({pincode: event.target.value})} />
                                    </div>
                                    <div className={styles.addDiv}>
                                        <input className={styles.address} type="text" placeholder="Address" value={this.state.address} onChange={(event) => this.setState({address: event.target.value})} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.bookDiv}>
                                <div onClick={() => this.navigateToStatus()} className={styles.book}>Book</div>
                            </div>                            
                            <img src={tablet} fluid className={styles.tablet} alt="tablet"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(DeliveryDetails)
