import React, { Component } from 'react'
import styles from './AppointmentNotification.module.css';

import calendar from '../../assets/images/calendar.svg'

export class AppointmentNotification extends Component {
    render() {
        var item = this.props.appointmentDetails;
        return (
            <div className={styles.apptNotificationDiv}>
                <div className={styles.calendarIconDiv}>
                    <img src={calendar} fluid className={styles.calendar} alt="calendar"/>
                </div>
                <div className={styles.notifTitleDiv}>
                    <div className={styles.notifTitle}>
                        {item.title}
                    </div>
                </div>
                <div className={styles.notifTextDiv}>
                    <div className={styles.notifText}>
                        {item.description}
                    </div>
                </div>
            </div>
        )
    }
}

export default AppointmentNotification
