import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

import styles from './OrderHistory.module.css'

import back from '../../assets/images/back.svg'
import tablet from '../../assets/images/rtablet.svg'
import prescription2 from '../../assets/images/prescription2.svg'
import { getOrderHistory, repeatOrder } from '../../apis'

export class OrderHistory extends Component {

    state = {
        previousOrders: [{
            orderId: 345678,
            date: "Jun 18th, 2020",
            billamount: 450,
            medicines: [{
                name: 'AlChirrrlegra 20mg',
                quantity: 2
            }, {
                name: 'Cura 07',
                quantity: 5
            }]
        }, {
            orderId: 3345678,
            date: "Jun 18th, 2020",
            billamount: 700,
            medicines: [{
                name: 'Bhurr 20mg',
                quantity: 2
            }, {
                name: 'Cura 07',
                quantity: 5
            }]
        }, {
            orderId: 87678,
            date: "Jun 18th, 2020",
            billamount: 932,
            medicines: [{
                name: 'Gol 20mg',
                quantity: 2
            }, {
                name: 'Cura 07',
                quantity: 5
            }]
        }, {
            orderId: 3456,
            date: "Jun 18th, 2020",
            billamount: 456,
            medicines: [{
                name: 'Magd 20mg',
                quantity: 2
            }, {
                name: 'Cura 07',
                quantity: 5
            }]
        }, {
            orderId: 98765,
            date: "Jun 18th, 2020",
            billamount: 1123,
            medicines: [{
                name: 'BLLasa 20mg',
                quantity: 2
            }, {
                name: 'Cura 07',
                quantity: 5
            }]
        }],
        pastOrders: []
    }

    componentDidMount() {
        this.getOrderHistory();
    }

    reorder = async(item) => {
        // ADD TO CART AND THEN NAVIGATE TO CARD
        await repeatOrder(item.id);
        window.location.href = "/cart";
    }

    getOrderHistory = async() => {
        var data = await getOrderHistory();
        this.setState({pastOrders: data});
        console.log("PAST")
        console.log(this.state.pastOrders)
    }

    renderOrderHistoryCard =  (item) => {
        return (
            <div className={styles.cardContainer}>
                <div className={styles.cardLeft}>
                    <div className={styles.orderId}>Order #{item.id}</div>
                    <div className={styles.date}>{new Date(Date.parse(item.created_at)).toDateString()}</div>
                    <div className={styles.productsList}>
                        {
                            item.orderitem.map((prod, i) => {
                                return(
                                    <div className={styles.product}>{prod.product.name} ({prod.quantity}) {i === item.orderitem.length - 1 ? "" : ","}</div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={styles.cardRight}>
                    <div className={styles.price}> {item.final_amount} ₹ </div>
                    <div onClick={() => this.reorder(item)} className={styles.reorder}> Reorder </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                    <Link to="/" className={styles.back}>
                        <img src={back} fluid className={styles.backicon} alt="back"/>
                    </Link>

                    <div className={styles.innerBody}>
                        <div className={styles.left}>
                            <div className={styles.lTop}>
                                <div className={styles.lTopTitle}>Order<br/>History</div>
                                <div className={styles.lTopSubtitle}>View all your previous orders here and repeat orders if necessary. Dont forget to reupload your prescription</div>
                                <div className={styles.prescription}>
                                    <img src={prescription2} fluid className={styles.prescription2} alt="prescription2"/>
                                </div>
                            </div>
                            <div className={styles.lBottom}>
                                <div className={styles.lBottomTitle}>Having trouble placing your order?</div>
                                <div className={styles.lBottomSubtitle}>Call us right away!</div>
                                <div className={styles.phoneDiv}>
                                    <a className={styles.lBottomPhone} href="tel:+911204030499">+911204030499</a>
                                </div>
                            </div>
                        </div>
                        <div className={styles.right}>
                            {
                                this.state.pastOrders.map((item) => {
                                    return (
                                        <div>
                                            {this.renderOrderHistoryCard(item)}
                                        </div>
                                    )
                                })
                            }
                            <img src={tablet} fluid className={styles.tablet} alt="tablet"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(OrderHistory)
