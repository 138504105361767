import React, { Component } from 'react'
import styles from './Home.module.css';


import Fold1Cards from './Fold1Cards/Fold1Cards';
import ProductCard from '../../components/ProductCard/ProductCard';
import Footer from '../../components/Footer/Footer';
import FixedFooter from '../../components/FixedFooter/FixedFooter';

import search from '../../assets/images/search.svg'
import mask from '../../assets/images/mask.svg'
import plus from '../../assets/images/plus.svg'
import sanitizer from '../../assets/images/sanitizer.svg'
import handwash from '../../assets/images/handwash.svg'
import covidarrow from '../../assets/images/covidarrow.svg'
import Select, { components } from 'react-select'

import AppointmentNotification from '../../components/AppointmentNotification/AppointmentNotification';
import { Link } from 'react-router-dom';
import { getOrderHistory, getProductList, liveOrders, repeatOrder, searchList, searchSuggestions, getLatestDocAppointment } from '../../apis';

var options = []

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
           <div style={{zIndex: 100}} className={styles.searchIconDiv}>
                <img src={search} fluid className={styles.searchicon} alt="search"/>
            </div>
        </components.DropdownIndicator>
    );
};

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        margin: 0,
        marginBottom: 25,
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 12px 42px rgba(0, 0, 0, 0.0917013)',
        borderRadius: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px 10px 20px'
    }),
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        border: 'none',
        outline: 'none'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        border: 'none',
        outline: 'none'
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        border: 'none',
        borderColor: 'transparent',
        outline: 'none'
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        border: 'none',
        borderColor: 'transparent',
        outline: 'none'
    }),
    input: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        outline: 'none',
        fontFamily: "Poppins",
        fontSize: 16
    }),
    placeholder: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        outline: 'none',
        fontFamily: "Poppins",
        fontSize: 18,
    }),
    menu: (provided, state) => ({
        ...provided,
        width: '96.5%',
        border: '2px solid red',
        textAlign: 'left',
        borderColor: 'transparent',
        outline: 'none',
        fontFamily: "Poppins",
        fontSize: 18
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        outline: 'none',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        outline: 'none',
    }),
}

const MenuItem = ({item, selected}) => {
    return (
        <Link
            to={{
            pathname:'/description',
            state: { item: item }
            }}
            style={{textDecoration: 'none', color: '#000'}}>
            <ProductCard product={item} selected={selected} {...this.props}/>
        </Link>
    )
  };
   
export const Menu = (noPrescriptionProductsList, selected) =>
    noPrescriptionProductsList.map(li => {
      return <MenuItem item={li} key={li.name} selected={selected} />;
    });

   
export class Home extends Component {

    state = {
        selected: "",
        appointmentDetails: {},
        search: "",
        noPrescriptionProductsList: [],
        discountedProductsList: [],
        pastOrders:[],
        liveOrders: [
            {
                orderId: 987678,
                date: "Jun 26th, 2020",
                billamount: 950,
                orderitem: [{product : {
                    name: 'Allegra 20mg',
                    quantity: 2
                }, quantity: 2}],
                live_status: {
                    code: 'Accepted',
                    msg: 'Your order has been accepted',
                    buttonText: 'Make Payment'
                }
            }
        ]
    };

    componentDidMount() {
        this.getOTCProducts();
        this.getOrderHistory();
        this.getLiveOrders();
        this.getDocAppointment();
    }

    getDocAppointment = async() => {
        var data = await getLatestDocAppointment();
        data = JSON.parse(data);
        if (data.consultation_type) {    
            var docAppt = {
                title: 'Doctor\'s Appointment',
                description: `Your ${data.consultation_type} doctor’s appointment has been booked for ${new Date(data.date).toDateString()}, at ${data.time_start}. You shall recieve a call 15 mins prior to your appointment.`
            }
            this.setState({appointmentDetails: docAppt})
        }
        console.log("LATEST DOC APPOINTMENT")
        console.log(data)
    }

    getOrderHistory = async() => {
        var data = await getOrderHistory();
        this.setState({pastOrders: data});
        console.log("PAST ORDERS")
        console.log(data)
    }

    getLiveOrders = async() => {
        var data = await liveOrders();
        data = JSON.parse(data)
        this.setState({liveOrders: data});
        console.log("LIVE ORDERS")
        console.log(this.state.liveOrders)
    }

    getOTCProducts = async() => {
        var data = await getProductList("otc_medicines", 0);
        console.log(data)
        if (data[0])
            this.setState({noPrescriptionProductsList: data});
    }

    onSelect = key => {
        this.setState({ selected: key });
      }
      
    repeatLastOrder = async(id) => {
        // ADD TO CART AND NAVIGATE TO CART PAGE
        await repeatOrder(id);
        window.location.href = "/cart";
    }

    makePayment = () => {
        //NAVIGATE TO CONFIRM PAYMENT SCREEN
        // this.state.liveOrders[0]
        this.props.history.push({
            pathname:'/payment-details',
            state: { orderid: this.state.liveOrders[0].id}
        });
    }

    renderButtonAccordingToStatus = () => {
        if (this.state.liveOrders[0].live_status.buttonText) {
            if (this.state.liveOrders[0].live_status.code === 'Accepted') {
                return (
                    <div onClick={() => this.makePayment()} className={styles.robutton}>{this.state.liveOrders[0].live_status.buttonText}</div>
                )
            }
        }  
    }
    

    searchSuggestions = async(val) => {
        var data = await searchSuggestions(val);
        console.log('SEARCH SUGGESTIONS')
        console.log(data);
        data = JSON.parse(data);
        for (let i=0; i<data.length; i++) {
            var opt = {
                value: data[i].name, 
                label: data[i].name
            }
            if (!options.some(obj => obj.value === data[i].name)) {
                options.push(opt)
            }
            console.log(options)
        }
        console.log(options)
    }

    search = async() => {
        var data = await searchList(this.state.search);
        console.log('SEARCH')
        console.log(data)
        options = [];
        this.props.history.push({
            pathname:'/view-all',
            state: { list: JSON.parse(data) }
        });
    }

    handleInputChange = (inputValue, actionMeta) => {
        console.group('Input Changed');
        console.log(inputValue);
        this.setState({search: inputValue});
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        this.searchSuggestions(inputValue)
      };

    render() {
       
        // const { selected } = this.state;
        // Create menu from items
        // const menu = this.menuItems;
        // const discountedMenu = this.discountedMenuItems;
        // const covidEssentialMenu = this.covidEssentialMenuItems;

        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.outerBody}>
                <div className={styles.body}>
                    {/* <div className={styles.searchDiv}> 
                        <input className={styles.search} autocomplete="on" type="search" placeholder="Search for medicines and health products here" value={this.state.search} onChange={(event) => this.searchSuggestions(event)} />
                        <div onClick={() => this.search()} className={styles.searchIconDiv}>
                            <img src={search} fluid className={styles.searchicon} alt="search"/>
                        </div>
                    </div> */}

                    <Select 
                    styles={customStyles} 
                    placeholder="Search for products here"
                    onInputChange={this.handleInputChange}
                    onChange={this.search}
                    components={{ DropdownIndicator }}
                    options={options} 
                    />

                    {
                    this.state.liveOrders.length>0 || this.state.pastOrders.length>0 ?
                    <div className={styles.lastOrderDiv}>
                        <div className={styles.apptLeft}>
                            <div className={styles.orderStatus}>
                                <div className={styles.plus2Div}>
                                    <img src={plus} fluid className={styles.plus2} alt="plus"/>
                                </div>
                                {
                                    this.state.liveOrders.length > 0 ?
                                    <div className={styles.orderHistoryTitle}>{this.state.liveOrders[0].live_status.msg}</div> :
                                    this.state.pastOrders.length > 0 ?
                                    <div className={styles.orderHistoryTitleP}>Your Previous order</div> : ""
                                }
                            </div>
                            {
                                this.state.liveOrders.length > 0 ? 
                                <div className={styles.topOH}>
                                    <div className={styles.orderDetails}>
                                        <div className={styles.orderId}>Order ID {this.state.liveOrders[0].id}</div>
                                        <div className={styles.date}>{new Date(this.state.liveOrders[0].created_at).toDateString()}</div>
                                    </div>

                                    {this.state.liveOrders[0].orderitem.length>0 ? 
                                    <div className={styles.medicines}>
                                        {this.state.liveOrders[0].orderitem[0].product.name} ({this.state.liveOrders[0].orderitem[0].quantity}) {this.state.liveOrders[0].orderitem.length>1 ? ", ..." : ""} 
                                    </div> : "" }
                                </div> : this.state.pastOrders.length > 0 ? 
                                <div className={styles.topOH}>
                                    <div className={styles.orderDetails}>
                                        <div className={styles.orderId}>Order ID {this.state.pastOrders[0].id}</div>
                                        <div className={styles.date}>{new Date(Date.parse(this.state.pastOrders[0].created_at)).toDateString()}</div>
                                    </div>
                                    <div className={styles.medicines}>
                                        {this.state.pastOrders[0].orderitem[0].product.name} ({this.state.pastOrders[0].orderitem[0].quantity}) {this.state.pastOrders[0].orderitem.length>1 ? ", ..." : ""}
                                    </div>
                                </div> : ""
                            }
                        </div>
                       {
                           this.state.liveOrders.length > 0 ? 
                           <div className={styles.repeatOrder}>
                                {this.renderButtonAccordingToStatus()}
                                <a href="/order-history" className={styles.orderHistory}>View all orders</a>
                            </div> :
                            this.state.pastOrders.length > 0 ?
                            <div className={styles.repeatOrder}>
                                    <div onClick={() => this.repeatLastOrder(this.state.pastOrders[0].id)} className={styles.robutton}>Repeat Order</div>
                                    <a href="/order-history" className={styles.orderHistory}>View order history</a>
                                </div> : ""
                                // <div className={styles.nof}>
                                //     Oops No Orders Found!
                                // </div>
                       }
                    </div> : ""
                    }

                    <Fold1Cards />

                    {
                        this.state.appointmentDetails.title ?
                        <AppointmentNotification appointmentDetails={this.state.appointmentDetails}/> : 
                        ""
                    }

                    <div className={styles.bookApptDiv}>
                        <div className={styles.apptLeft}>
                            <div className={styles.plus}>
                                <img src={plus} fluid className={styles.plusS} alt="plus"/>
                            </div>
                            <div className={styles.apptTitle}>Schedule a doctor’s appointment</div>
                        </div>
                        <div className={styles.apptRightDiv}>
                            <a href="https://www.drome.co.in/book-appointment" className={styles.apptRight}>
                                Book Now
                            </a>
                        </div>
                    </div>
                    
                    

                    <div className={styles.section}>
                        <div className={styles.titleSection}>
                            <div className={styles.titleSectionText}>
                                Medicines you can<br/>order without Prescription
                            </div>
                            <Link
                             to={{
                                pathname:'/view-all',
                                state: { list: this.state.noPrescriptionProductsList, page: 0, category: "otc_medicines" }
                             }}
                             style={{textDecoration: 'none'}} 
                             className={styles.titleSectionButton}>
                                View All
                            </Link>
                        </div>
                        <div className={styles.bodySection}>
                            <div className={styles.covidEssentialsDiv2}>
                                {
                                    this.state.noPrescriptionProductsList.map(item => {
                                        return(
                                            <Link
                                            to={{
                                            pathname:'/description',
                                            state: { item: item }
                                            }}
                                            style={{textDecoration: 'none', color: '#000'}}>
                                                <ProductCard product={item} {...this.props}/>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.titleSection}>
                            <div className={styles.titleSectionText}>
                                Covid<br/>Essentials
                            </div>
                        </div>
                        <div className={styles.bodySection}>
                            <div className={styles.covidEssentialsDiv}>
                                <div className={styles.covidEssential}>
                                    Mask <span className={styles.brSpan}><br/></span> & Sanitizers<br/>
                                    <img src={covidarrow} alt="covidarrow" fluid className={styles.covidarrow}/>
                                    <img src={mask} alt="mask" fluid className={styles.mask}/>
                                </div>
                                <div className={styles.covidEssential}>
                                    Best <br/> Sanitizers<br/>
                                    <img src={covidarrow} alt="covidarrow" fluid className={styles.covidarrow}/>
                                    <img src={sanitizer} alt="sanitizer" fluid className={styles.sanitizer}/>
                                </div>
                                <div className={styles.covidEssential}>
                                    Hand <br/> Washes<br/>
                                    <img src={covidarrow} alt="covidarrow" fluid className={styles.covidarrow}/>
                                    <img src={handwash} alt="handwash" fluid className={styles.handwash}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.section}>
                        <div className={styles.titleSection}>
                            <div className={styles.titleSectionText}>
                                Get the<br/>Best Prices
                            </div>
                            <Link 
                            to={{
                                pathname:'/view-all',
                                state: { list: this.state.noPrescriptionProductsList, page: 0, category: "otc_medicines" }
                             }}
                             style={{textDecoration: 'none'}}
                            className={styles.titleSectionButton}>
                                View All
                            </Link>
                        </div>
                        <div className={styles.bodySection}>
                            <div className={styles.covidEssentialsDiv2}>
                                {
                                    this.state.noPrescriptionProductsList.map(item => {
                                        return(
                                            <Link
                                            to={{
                                            pathname:'/description',
                                            state: { item: item }
                                            }}
                                            style={{textDecoration: 'none', color: '#000'}}>
                                                <ProductCard product={item} {...this.props} />
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <FixedFooter />
                <Footer />
            </div>
        )
    }
}

export default Home;
