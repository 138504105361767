import React, { Component } from 'react'
import styles from './Fold1Cards.module.css';

import mainCardImg from '../../../assets/images/main_card_img.svg'
import bottomCardRightArrow from '../../../assets/images/rightblack.svg'
// import bottomCardUpArrow from '../../../assets/images/upblack.svg'
import injection from '../../../assets/images/injection.svg'
import capsules from '../../../assets/images/capsules.svg'
import { Link } from 'react-router-dom';

export class Fold1Cards extends Component {

    navigateToBookAppointment = () => {
        window.location.href = "https://www.drome.co.in/book-appointment";
    }

    render() {
        return (
            <div>
                <div className={styles.mainCard}>
                    <div className={styles.mainCardLeft}>
                        <div className={styles.mainCardLeftTitle}>Instant order for <br/> Instant care</div>  
                        <div className={styles.mainCardLeftSubtext}>Order quality healthcare in just a few taps and clicks!</div>
                    </div>
                    <div className={styles.mainCardImgDiv}>
                        <img src={mainCardImg} fluid className={styles.mainCardImg} alt="mainCardImg"/>
                    </div>
                    <div className={styles.mainCardRight}>
                        <div className={styles.mainCardRightCoupon}>
                            <span className={styles.mainCardRightCouponSpan}>Get 10% off on your first purchase</span> from Drome Pharmacy
                        </div>
                    </div>
                </div>

                <div className={styles.bottomCardDiv}>
                    {
                        localStorage.getItem('user') ?
                        <Link to="/prescription-upload" style={{textDecoration: 'none'}} className={styles.bottomCardLeft}>
                            <a style={{textDecoration: 'none'}} href='/prescription-upload'>
                                <img src={injection} fluid className={styles.injection} alt="injection"/>
                                <div className={styles.bottomCardTitleDiv}>
                                    <div className={styles.bottomCardTitle}>
                                        <span className={styles.bold}>Upload</span> <br/> Prescription
                                    </div>
                                    <div className={styles.bottomCardLeftArrow}>
                                        <img src={bottomCardRightArrow} fluid className={styles.bottomCardRightArrow} alt="bottomCardRightArrow"/>
                                    </div>
                                </div>
                                <div className={styles.bottomCardSubtext}>
                                    Prescriptions are a must to order certain medicines
                                </div>
                            </a>
                        </Link> : 
                        <div onClick={() => alert('Please sign in first!')} style={{textDecoration: 'none'}} className={styles.bottomCardLeft}>
                            {/* <a style={{textDecoration: 'none'}} href='/prescription-upload'> */}
                                <img src={injection} fluid className={styles.injection} alt="injection"/>
                                <div className={styles.bottomCardTitleDiv}>
                                    <div className={styles.bottomCardTitle}>
                                        <span className={styles.bold}>Upload</span> <br/> Prescription
                                    </div>
                                    <div className={styles.bottomCardLeftArrow}>
                                        <img src={bottomCardRightArrow} fluid className={styles.bottomCardRightArrow} alt="bottomCardRightArrow"/>
                                    </div>
                                </div>
                                <div className={styles.bottomCardSubtext}>
                                    Prescriptions are a must to order certain medicines
                                </div>
                            {/* </a> */}
                        </div>
                    }
                    <div onClick={() => this.navigateToBookAppointment()} className={styles.bottomCardCenter}>
                        <div className={styles.bottomCardTitleDiv}>
                            <div className={styles.bottomCardTitle}>
                                <span className={styles.bold}>Consult doctor </span><br/>for Prescription
                            </div>
                            <div className={styles.bottomCardRightArrow}>
                                <img src={bottomCardRightArrow} fluid className={styles.bottomCardRightArrow} alt="bottomCardRightArrow"/>
                            </div>
                        </div>
                        <div className={styles.bottomCardSubtext}>
                            Get a consulation now to recieve your prescription.
                        </div>
                    </div>
                    <Link to="/videocall-appointment" style={{textDecoration: 'none'}}  className={styles.bottomCardRight}>
                        <img src={capsules} fluid className={styles.capsules} alt="capsules"/>
                        <div className={styles.bottomCardTitleDiv}>
                            <div className={styles.bottomCardTitle}>
                            <span className={styles.bold}> Video Call </span> <br/> with Pharmacist
                            </div>
                            <div className={styles.bottomCardRightArrow}>
                                <img src={bottomCardRightArrow} fluid className={styles.bottomCardRightArrow} alt="bottomCardRightArrow"/>
                            </div>
                        </div>
                        <div className={styles.bottomCardSubtext}>
                            Get on a quick call with your pharmacist if you need anything
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}

export default Fold1Cards;
