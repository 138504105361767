const firebaseConfig = {
    apiKey: "AIzaSyDjNnIB1Q0Uqjjn301Fumz5A4MymYxAjs8",
    authDomain: "drome-298708.firebaseapp.com",
    projectId: "drome-298708",
    storageBucket: "drome-298708.appspot.com",
    messagingSenderId: "510157238830",
    appId: "1:510157238830:web:db31cbaba0144230e925bf",
    measurementId: "G-SGP6S0VHF1"
  };

  export default firebaseConfig;