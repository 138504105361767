import React, { Component } from 'react'

import styles from './CartItem.module.css'

import del from '../../assets/images/del.svg';
import { deleteProductCart } from '../../apis';
import cx from "classnames";
export class CartItem extends Component {

    minusQuantity = (item) => {
       
        this.props.minusQuantity(item)
    }

    plusQuantity = (item) => {
        this.props.plusQuantity(item)
    }

    deleteProduct = async(item) => {
        await deleteProductCart(item.id);
        window.location.reload(false);
    }

    render() {
        var item = this.props.item;
        return (
        <div className={styles.cartItemContainer}>
            <div className={styles.top}>
                <div className={styles.left}>
                    <div className={styles.nameRow}>
                        <div className={styles.name}>{item.product.name}</div>
                        {
                            item.product.medicine.is_scheduled ? 
                            <div className={styles.isPrescriptionDrug}>Need to upload prescription</div> :
                            ""
                        }
                    </div>
                    <div className={styles.description}>{item.product.medicine ? item.product.medicine.pharmaceutical_form : ""} {item.product.medicine.medicinecontents.length > 0 ? " - " + item.product.medicine.medicinecontents[0].content_quantity + " " + item.product.medicine.medicinecontents[0].content_units : ""}</div>
                </div>
                <div className={styles.right}>
                    <div className={styles.price}>{item.product.discount ? <span className={styles.originalPrice}>₹ {Math.round(item.quantity*item.product.mrp_price*100)/100}</span> : ""}₹ {Math.round(item.quantity*item.product.price *100 )/100}</div>
                </div>
            </div>

            <div className={styles.bottom}>
                {
                    this.props.approved 
                    ? 
                    <div className={styles.bottomW}>
                        <div />
                        <div className={styles.quantityModifierW}>
                            <div className={styles.inputgroup}>
                                
                            {item.quantity !==1  &&<input disabled type="button" onClick={() => {this.minusQuantity(item)}} value="-" className={styles.minus} data-field="quantity" />}
                                <input disabled step="1" max="" value={item.quantity} name="quantity" className={styles.val} />
                                <input disabled type="button" onClick={() => {this.plusQuantity(item)}} value="+" className={styles.plus} data-field="quantity" />
                            </div>
                        </div>
                    </div>
                    : 
                    <div className={styles.bottomW}>
                        
                       <img  onClick={() => this.deleteProduct(item)} src={del} alt="del" fluid className={cx("pointer" , styles.del)}/>
                        <div className={styles.quantityModifier}>
                            <div className={styles.inputgroup}>
                            {item.quantity !==1  && <input type="button" onClick={() => {this.minusQuantity(item)}} value="-" className={styles.minus} data-field="quantity" />}
                                <input step="1" max="" value={item.quantity} name="quantity" className={styles.val} />
                                <input type="button" onClick={() => {this.plusQuantity(item)}} value="+" className={styles.plus} data-field="quantity" />
                            </div>
                        </div>
                    </div>
                }
                
            </div>
        </div>
        )
    }
}

export default CartItem
