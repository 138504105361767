import React, { Component } from 'react'
import styles from './SubscribePlan.module.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import cross from '../../../assets/images/cross.svg'
import videocall from '../../../assets/images/callWithDoc.svg'
import { Link } from 'react-router-dom';
import { createPlanSubscription, payForSubscription, paymentSuccess } from '../../../apis';

export class SubscribePlan extends Component {

    state = {
        name: '',
        mobile: '',
        email: '',
        bookingFor: 'self',
        timeSlot: '8Ato9P',
        date: '',
        plan: 'self',
        address: '',
        orderCreated: false,
        orderid: '',
        plancost: '',
        duration: 6,
        is_tele_family: false,
        is_senior_couple: false
    }

    createOrder = async() => {
        if (this.state.name && this.state.plan && this.state.email && this.state.mobile && this.state.address) {
            var bodyData = {
                name: this.state.name,
                plan: this.state.plan,
                email: this.state.email,
                mobile: this.state.mobile,
                address: this.state.address,
                num_months: this.state.duration,
                is_couple: this.state.is_senior_couple,
                is_family: this.state.is_tele_family
            }
            var data = JSON.parse(await createPlanSubscription(bodyData));
            console.log('SUBSCRIBE PLAN ORDER CREATED')
            console.log(data);
            if (data.id && data.plan_cost) {
                this.setState({
                    orderid: data.id,
                    plancost: data.plan_cost,
                    orderCreated: true
                });
            }
        } else {
            alert('Please fill all details');
        }
    }

    bookNow = async() => {
        await this.createOrder();
        var data = JSON.parse(await payForSubscription(this.state.orderid));
        console.log("PAY FOR SUBSCRIPTION")
        console.log(data)
        if (data.order_id) {
            var options = {
                "key": "rzp_test_t9djsv93UtOV1Q", // Enter the Key ID generated from the Dashboard
                "amount": this.state.plancost*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                "currency": "INR",
                "name": this.state.name,
                "image": "public/favicon.ico",
                "description": this.state.plan + " plan",
                "order_id": data.order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                "handler": async function (response){
                    console.log("RAZOR PAY SUCCESS HANDLER");
                    console.log(response.razorpay_payment_id);
                    console.log(response.razorpay_order_id);
                    console.log(response.razorpay_signature);
                    var bodyData = {
                        razorpay_payment_id: response.razorpay_payment_id,
                        razorpay_order_id: response.razorpay_order_id,
                        razorpay_signature: response.razorpay_signature
                    }
                    var bbb = JSON.parse(await paymentSuccess(data.payment_id, bodyData));
                    console.log("PAYMENT SUCCESSFULL BACKEND REQUEST")
                    console.log(bbb);
                    window.location.href = "https://www.drome.co.in/";
                },
                "prefill": {
                    "name": this.state.name,
                    "email": this.state.email,
                    "contact": this.state.mobile
                },
                "notes": {
                    "address": "Drome Plan Subscription"
                },
                "theme": {
                    "color": "#3399cc"
                }
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.open();
        }
    }

    bookVideoCall = () => {
        alert("Videocall Scheduled!");
        window.location.href = "/";
        // setTimeout(function(){ window.location.href = "/"; }, 3000);
    }

    getPrice = () => {
        if (this.state.timeSlot === '8Ato9P') {
            return 800;
        } else {
            return 1500;
        }
    }

    render() {
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                    <div className={styles.bluebox}>
                        <Link to="/" className={styles.crossDiv}>
                            <img src={cross} fluid className={styles.cross} alt="cross"/>
                        </Link>
                        <div className={styles.outerDiv}>
                            <div className={styles.leftDiv}>
                                <div className={styles.leftText}>
                                    <div className={styles.leftDivTitle}>“Health really is Wealth” and we prove it to you - Every step of the way</div>
                                    <div className={styles.leftDivSub}>Our Team of Doctors at your service</div>
                                    {/* <div>
                                        <div className={styles.leftDivFreeConsultation}>Our first consultation is free</div>
                                    </div> */}
                                </div>
                                <div className={styles.imgDiv}>
                                    <img src={videocall} fluid className={styles.videocall} alt="videocall"/>
                                </div>
                            </div>
                            <div className={styles.rightDiv}>
                                <div className={styles.row}>
                                    <div className={styles.tag}>01 Your Name*</div>
                                    <div className={styles.value}>
                                        <input required className={styles.inputText} type="text" placeholder="e.g. Param Jain" value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} />
                                    </div>
                                </div>
                                {/* <div className={styles.row}>
                                    <div className={styles.tag}>02 Select a Slot*</div>
                                    <div className={styles.value}>
                                        <input className={styles.inputText} type="text" placeholder="Mobile No." value={this.state.mobile} onChange={(event) => this.setState({mobile: event.target.value})} />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.tag}></div>
                                    <div className={styles.value}>
                                        <input className={styles.inputText} type="text" placeholder="Email" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.tag}>Call Purpose</div>
                                    <div className={styles.value}>
                                        <select className={styles.inputSelect} value={this.state.callPurpose} onChange={(event) => this.setState({callPurpose: event.target.value})}>
                                            <option className={styles.option} value="Trouble Ordering">Trouble Ordering</option>
                                            <option className={styles.option} value="General Enquiry">General Enquiry</option>
                                        </select>
                                    </div>
                                </div> */}
                                <div className={styles.row}>
                                    <div style={{lineHeight: 1.2}} className={styles.tag}>02 Select a Plan*</div>
                                    <div className={styles.timeslotsDiv}>
                                        <div onClick={() => this.setState({plan: 'self', duration: 6})} className={this.state.plan === 'self' ? styles.timeslotActive : styles.timeslot}><b>Self</b></div>
                                        <div onClick={() => this.setState({plan: 'tele'})} className={this.state.plan === 'tele' ? styles.timeslotActive : styles.timeslot}><b>Tele</b></div>
                                        <div onClick={() => this.setState({plan: 'family', duration: 6})} className={this.state.plan === 'family' ? styles.timeslotActive : styles.timeslot}><b>Family</b></div>
                                        <div onClick={() => this.setState({plan: 'senior', duration: 6})} className={this.state.plan === 'senior' ? styles.timeslotActive : styles.timeslot}><b>Senior</b></div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div style={{lineHeight: 1.2}} className={styles.tag}></div>
                                    <div className={styles.timeslotsDiv}>
                                        {
                                            this.state.plan === 'tele' ? 
                                            <div className={styles.timeslotsDiv}>
                                                <div onClick={() => this.setState({is_tele_family: false})} className={!this.state.is_tele_family? styles.timeslotActive : styles.timeslot}><b>Indiviual</b></div> 
                                                <div onClick={() => this.setState({is_tele_family: true})} className={this.state.is_tele_family? styles.timeslotActive : styles.timeslot}><b>Family</b></div>
                                            </div> 
                                            : ''
                                        }
                                        {
                                            this.state.plan === 'senior' ? 
                                            <div className={styles.timeslotsDiv}>
                                                <div onClick={() => this.setState({is_senior_couple: false})} className={!this.state.is_senior_couple? styles.timeslotActive : styles.timeslot}><b>Single</b></div> 
                                                <div onClick={() => this.setState({is_senior_couple: true})} className={this.state.is_senior_couple? styles.timeslotActive : styles.timeslot}><b>Couple</b></div>
                                            </div> 
                                            : ''
                                        }
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div style={{lineHeight: 1.2}} className={styles.tag}>03 Select Duration*</div>
                                    <div className={styles.timeslotsDiv}>
                                        {
                                            this.state.plan === 'tele' ? <div onClick={() => this.setState({duration: 3})} className={this.state.duration === 3 ? styles.timeslotActive : styles.timeslot}><b>3 months</b></div> : ''
                                        }
                                        <div onClick={() => this.setState({duration: 6})} className={this.state.duration === 6 ? styles.timeslotActive : styles.timeslot}><b>6 months</b></div>
                                        <div onClick={() => this.setState({duration: 12})} className={this.state.duration === 12 ? styles.timeslotActive : styles.timeslot}><b>12 months</b></div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.tag}>04 Your Details*</div>
                                    <div className={styles.value}>
                                        <input style={{marginRight: 5}} className={styles.inputTextDetails} type="email" placeholder="Email" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} />
                                        <input style={{marginLeft: 5}} className={styles.inputTextDetails} type="phone" placeholder="Mobile No." value={this.state.mobile} onChange={(event) => this.setState({mobile: event.target.value})} />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.tag}></div>
                                    <div className={styles.value}>
                                        <input className={styles.inputTextEmail} type="text" placeholder="Address" value={this.state.address} onChange={(event) => this.setState({address: event.target.value})} />
                                    </div>
                                </div>
                                {/* <div className={styles.row}>
                                    <div style={{lineHeight: 1.2}} className={styles.tag}>04 Booking For*</div>
                                    <div className={styles.bookingForDiv}>
                                        <div onClick={() => this.setState({bookingFor: 'self'})} className={this.state.bookingFor === 'self' ? styles.bookingForActive : styles.bookingFor}>Self</div>
                                        <div onClick={() => this.setState({bookingFor: 'other'})} className={this.state.bookingFor === 'other' ? styles.bookingForActive : styles.bookingFor}>Other</div>
                                    </div>
                                </div> */}
                                <div className={styles.row}>
                                    <div style={{lineHeight: 1.2}} className={styles.tag}></div>
                                    <div className={styles.value}></div>
                                </div>
                                {
                                    this.state.orderCreated ? 
                                    <div className={styles.rowBook}>
                                        <div className={styles.bookText}>Book it for a price of <br/><span className={styles.price}>₹{this.state.plancost}</span></div>
                                        <div id="rzp-button1" onClick={() => this.bookNow()} className={styles.book}>Book</div>
                                    </div> :
                                    <div className={styles.rowBook}>
                                        <div className={styles.bookText}></div>
                                        <div onClick={() => this.createOrder()} className={styles.book}>Proceed</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

export default SubscribePlan
