import React, { Component } from 'react'
import styles from './ViewAll.module.css'

import { withRouter } from "react-router";

import back from '../../assets/images/back.svg'
import search from '../../assets/images/search.svg'
import ProductCard from '../../components/ProductCard/ProductCard'
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select'

import { getProductList, searchList, searchSuggestions} from '../../apis';


var options = []

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
           <div style={{zIndex: 100}} className={styles.searchIconDiv}>
                <img src={search} fluid className={styles.searchicon} alt="search"/>
            </div>
        </components.DropdownIndicator>
    );
};

const customStyles = {
    container: (provided, state) => ({
        ...provided,
        marginTop: 50,
        marginBottom: 25,
        width: '100%',
        background: '#FFFFFF',
        boxShadow: '0px 12px 42px rgba(0, 0, 0, 0.0917013)',
        borderRadius: 22,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 20px 10px 20px'
    }),
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        border: 'none',
        outline: 'none'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        border: 'none',
        outline: 'none'
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        border: 'none',
        borderColor: 'transparent',
        outline: 'none'
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        border: 'none',
        borderColor: 'transparent',
        outline: 'none'
    }),
    input: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        outline: 'none',
        fontFamily: "Poppins",
        fontSize: 16
    }),
    placeholder: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        outline: 'none',
        fontFamily: "Poppins",
        fontSize: 18,
    }),
    menu: (provided, state) => ({
        ...provided,
        width: '96.5%',
        border: '2px solid red',
        textAlign: 'left',
        borderColor: 'transparent',
        outline: 'none',
        fontFamily: "Poppins",
        fontSize: 18
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        outline: 'none',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        borderColor: 'transparent',
        outline: 'none',
    }),
}

export class ViewAll extends Component {
    
    searchSuggestions = async(val) => {
        var data = await searchSuggestions(val);
        console.log('SEARCH SUGGESTIONS')
        console.log(data);
        data = JSON.parse(data);
        for (let i=0; i<data.length; i++) {
            var opt = {
                value: data[i].name, 
                label: data[i].name
            }
            if (!options.some(obj => obj.value === data[i].name)) {
                options.push(opt)
            }
            console.log(options)
        }
        console.log(options)
    }

    search = async() => {
        var data = await searchList(this.state.search);
        console.log('SEARCH')
        console.log(data)
        options = [];
        this.props.history.push({
            pathname:'/view-all',
            state: { list: JSON.parse(data) }
        });
    }

    handleInputChange = (inputValue, actionMeta) => {
        console.group('Input Changed');
        console.log(inputValue);
        this.setState({search: inputValue});
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        this.searchSuggestions(inputValue)
      };

    nextClicked = async() => {
        var data = await getProductList(this.props.location.state.category, this.props.location.state.page+1);
        console.log("NEXT CLICKED ON VIEW ALL PAGE")
        console.log(data)
        var category = this.props.location.state.category;
        var page = this.props.location.state.page+1;
        this.props.history.push({
            pathname:'/view-all',
            state: { list: data, page: page, category: category }
        });
    }

    prevClicked = async() => {
        var data = await getProductList(this.props.location.state.category, this.props.location.state.page-1);
        console.log("PREVIOUS CLICKED ON VIEW ALL PAGE")
        console.log(data)
        var category = this.props.location.state.category;
        var page = this.props.location.state.page-1;
        this.props.history.push({
            pathname:'/view-all',
            state: { list: data, page: page, category: category }
        });
    }

    render() {
        console.log("VIEW ALL STATE")
        console.log(this.props.location.state)
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                    <Link to="/" className={styles.back}>
                        <img src={back} fluid className={styles.backicon} alt="back"/>
                    </Link>

                    <Select 
                    styles={customStyles} 
                    placeholder="Search for products here"
                    onInputChange={this.handleInputChange}
                    onChange={this.search}
                    isClearable={true}
                    components={{ DropdownIndicator }}
                    options={options} 
                    />

                    <div className={styles.headDiv}>
                        <div className={styles.head}>All<br />products</div>
                        <div className={styles.subtitle}>{this.props.location.state.list.length} products</div>
                    </div>
                    <div className={styles.viewAllDiv}>
                        {  
                            this.props.location.state.list.map((item) => {
                                return (
                                    <div className={styles.gridItem}>
                                        <Link
                                            to={{
                                            pathname:'/description',
                                            state: { item: item }
                                            }}
                                            style={{textDecoration: 'none', color: '#000'}}>
                                            <ProductCard product={item}/>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {   this.props.location.state.page!==undefined
                        ?
                        <div className={styles.bottomButtons}>
                        {
                            this.props.location.state.page<1 ? <div style={{textDecoration: 'underline', cursor: 'pointer'}}></div> : <div onClick={() => this.prevClicked()} style={{textDecoration: 'underline'}}>Previous</div>
                        }
                        <div>Page No. {this.props.location.state.page+1}</div>
                        {
                            this.props.location.state.list.length<25 ? "" : <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => this.nextClicked()}>Next</div>
                        }
                    </div> : ""
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(ViewAll)
