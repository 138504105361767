import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from "react-router";

import styles from './Description.module.css'

import back from '../../assets/images/back.svg'
import { addProductToCart, requestProduct } from '../../apis';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export class Description extends Component {

    constructor(props) {
        super(props);
        console.log(props)
    }

    addToCart = async(item) => {
        // ADD ITEM TO CART & NAVIGATE TO HOME
        if (localStorage.getItem('user')) {
            await addProductToCart(item.id)
            console.log(this.props);
            toast("Product added to cart!")
            // window.location.href = "/";
        } else {
            alert("Please sign in first!");
        }
    }

    requestProduct = async (event, item) => {
        event.preventDefault();
        if (localStorage.getItem('user')) {
            event.preventDefault()
            console.log("PRODUCT REQUESTED")
            console.log(item)
            await requestProduct(item.id)
        } else {
            alert("Please sign in first!");
        }
    }

    buyNow = async(item) => {
        // ADD ITEM TO CART & NAVIGATE TO CART
        if (localStorage.getItem('user')) {
            await addProductToCart(item.id)
            window.location.href = "/cart";
        } else {
            alert("Please sign in first!");
        }
    }

    render() {
        const { item } = this.props.location.state;
        console.log(item)
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                    <Link to="/" className={styles.back}>
                        <img src={back} fluid className={styles.backicon} alt="back"/>
                    </Link>
                    <div className={styles.headDiv}>
                        <div className={styles.head}>Medicine<br />Description</div>
                    </div>
                    <div className={styles.descDiv}>
                        <div className={styles.section1}>
                            <div className={styles.imgDiv}>
                            {   item.img_url !== "" ?
                                <img src={item.img_url} className={styles.cardImg} alt="item"/> :
                                <img src="https://api.drome.app/media/productimages/default_tablet.png" className={styles.cardImg} alt="item"/>
                            }
                            </div>
                            <div className={styles.txtDiv}>
                                <div className={styles.name}>{item.name} - {item.short_description}</div>
                                <div className={styles.price}>₹{item.price} <span className={styles.ppiece}> /piece</span></div>
                                <div className={styles.discount}>
                                    {item.discount ? "₹" + item.discount + " Off" : ""}
                                </div>
                            </div>
                        </div>
                        {
                            item.attributes.map(att => {
                                return(
                                    <div className={styles.section}>
                                        <div className={styles.sectionTitle}>{att.name}</div>
                                        <div className={styles.sectionBody}>{att.value}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={styles.footer}>
                    {
                        item.inventory_qty > 0 ?
                        <div className={styles.buttonsDiv}>
                            <div onClick={() => this.addToCart(item)} className={styles.addToCart}>Add to Cart</div>
                            {/* <div onClick={() => this.buyNow(item)} className={styles.buyNow}>Buy Now</div> */}
                        </div> :
                        <div className={styles.priceDiv}>
                            <div>
                                    <div style={{color:"#black"}} className={styles.discount}>
                                        Out of stock
                                    </div>
                            </div> 
                            <div style={{backgroundColor:"#FFF2F1"}} onClick={(event) => this.requestProduct(event, item)} className={styles.requestButton}>
                                Request
                            </div>
                        </div>
                    }
                    </div>
                </div>
                <ToastContainer 
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    onClick={() => window.location.href = "/cart"}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
            </div>
        )
    }
}

export default withRouter(Description)
