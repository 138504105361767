import React, { Component } from 'react'
import styles from './VideoCallAppointment.module.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import cross from '../../assets/images/cross.svg'
import videocall from '../../assets/images/videocall.svg'
import { Link } from 'react-router-dom';
import { callPharmacist } from '../../apis';

export class VideoCallAppointment extends Component {

    state = {
        name: "",
        mobile: "",
        email: "",
        callPurpose: "Trouble Ordering",
        callWhen: 15,
    }

    bookVideoCall = async() => {
        if (this.state.name && this.state.email && this.state.mobile && this.state.callPurpose && this.state.callWhen) {
            var bodyData = {
                name: this.state.name,
                email: this.state.email,
                mobile: this.state.mobile,
                purpose: this.state.callPurpose,
                schedule_mins: this.state.callWhen
            }
            await callPharmacist(bodyData);
            alert("Videocall Scheduled!");
            window.location.href = "/";
        } else {
            alert("Please fill all the fields!");
        }
    }

    render() {
        return (
            <div className={styles.container}>
                {/* <Header /> */}
                <div className={styles.body}>
                    <div className={styles.bluebox}>
                        <Link to="/" className={styles.crossDiv}>
                            <img src={cross} fluid className={styles.cross} alt="cross"/>
                        </Link>
                        <div className={styles.outerDiv}>
                            <div className={styles.leftDiv}>
                                <div className={styles.leftText}>
                                    <div className={styles.leftDivTitle}>Video call</div>
                                    <div className={styles.leftDivSub}>with pharmacist</div>
                                    <div>
                                        <div className={styles.leftDivFreeConsultation}>Our first consultation is free</div>
                                    </div>
                                </div>
                                <div className={styles.imgDiv}>
                                    <img src={videocall} fluid className={styles.videocall} alt="videocall"/>
                                </div>
                            </div>
                            <div className={styles.rightDiv}>
                                <div className={styles.row}>
                                    <div className={styles.tag}>Your Details</div>
                                    <div className={styles.value}>
                                        <input className={styles.inputText} type="text" placeholder="Name" value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.tag}></div>
                                    <div className={styles.value}>
                                        <input className={styles.inputText} type="text" placeholder="Mobile No." value={this.state.mobile} onChange={(event) => this.setState({mobile: event.target.value})} />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.tag}></div>
                                    <div className={styles.value}>
                                        <input className={styles.inputText} type="text" placeholder="Email" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} />
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div className={styles.tag}>Call Purpose</div>
                                    <div className={styles.value}>
                                        <select className={styles.inputSelect} value={this.state.callPurpose} onChange={(event) => this.setState({callPurpose: event.target.value})}>
                                            <option className={styles.option} value="Trouble Ordering">Trouble Ordering</option>
                                            <option className={styles.option} value="General Enquiry">General Enquiry</option>
                                        </select>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div style={{lineHeight: 1.2}} className={styles.tag}>When should<br/>we call you</div>
                                    <div className={styles.timeslotsDiv}>
                                        <div onClick={() => this.setState({callWhen: 15})} className={this.state.callWhen === 15 ? styles.timeslotActive : styles.timeslot}>In<br/><b>15 min</b></div>
                                        <div onClick={() => this.setState({callWhen: 30})} className={this.state.callWhen === 30 ? styles.timeslotActive : styles.timeslot}>In<br/><b>30 min</b></div>
                                        <div onClick={() => this.setState({callWhen: 45})} className={this.state.callWhen === 45 ? styles.timeslotActive : styles.timeslot}>In<br/><b>45 min</b></div>
                                        <div onClick={() => this.setState({callWhen: 60})} className={this.state.callWhen === 60 ? styles.timeslotActive : styles.timeslot}>In<br/><b>60 min</b></div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <div style={{lineHeight: 1.2}} className={styles.tag}></div>
                                    <div className={styles.value}></div>
                                </div>
                                <div className={styles.row}>
                                    <div style={{lineHeight: 1.2}} className={styles.tag}></div>
                                    <div onClick={() => this.bookVideoCall()} className={styles.book}>Book</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        )
    }
}

export default VideoCallAppointment
